import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';

const Layout = () => {
  return (
    <>
      <div id="cryptoon-layout" className="theme-orange">
        <Sidebar/>
        <div className="main px-lg-4 px-md-4">
          <Header />
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Layout;
