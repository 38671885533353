import { useState, React } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import iconimg from "../assets/logo.png";

const Sidebar = () => {

  const location = useLocation();
  const [currentRoute, setCurrentRoute] = useState('');

  useEffect(() => {
      setCurrentRoute(location.pathname);
  }, [location]);


  return (
    <div className="sidebar py-2 py-md-2 me-0 border-end">
      <div className="d-flex flex-column h-100">
        <Link to="/user/dashboard" className="mb-0 brand-icon">
          <span className="logo-icon">
            <img src={iconimg} width={50}/>
          </span>
          <span className="logo-text">DCI Wallet</span>
        </Link>
        <ul className="menu-list flex-grow-1 mt-4 px-1">
          <li>
            <Link className={currentRoute === '/user/dashboard' ? 'm-link active' : 'm-link'} to="/user/dashboard">
              <svg xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" width="24px" height="24px" viewBox="0 0 38 38">
              <path xmlns="http://www.w3.org/2000/svg"  d="M34,18.756V34H22v-8h-6v8h-4V14.31l7-3.89L34,18.756z M34,16.472V6h-6v7.139L34,16.472z" style={{ fill: 'var(--primary-color)' }} data-st="fill:var(--chart-color4);"></path>
              <path xmlns="http://www.w3.org/2000/svg" className="st0" d="M34,14.19V6h-6v2h4v5.08L19,5.86L0.51,16.13l0.98,1.74L19,8.14l17.51,9.73l0.98-1.74L34,14.19z M32,32h-8v-8H14  v8H6V17.653l-2,1.111V34h12v-8h6v8h12V18.764l-2-1.111V32z"></path>
              </svg>
              <div><h6 className="mb-0">Dashboard</h6><small className="text-muted">Analytics Report</small></div>
            </Link>
          </li>
          <li>
            <Link className={currentRoute === '/user/plans' ? 'm-link active' : 'm-link'} to="/user/plans">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 64 64">
                <path className="st0" d="M21.972,34.25c0-4.906,3.495-8.992,8.13-9.912l-3.492-7.764L14.317,40.282l8.673-1.635
                    C22.345,37.315,21.972,35.828,21.972,34.25z M41.17,38.647l8.673,1.635L37.55,16.574l-3.491,7.764
                    c4.635,0.92,8.129,5.006,8.129,9.912C42.188,35.828,41.815,37.315,41.17,38.647z M32.08,26.968c-3.875,0-7.016,3.141-7.016,7.016
                    c0,3.874,3.141,7.016,7.016,7.016c3.875,0,7.018-3.142,7.018-7.016C39.098,30.109,35.955,26.968,32.08,26.968z"></path>
                <path className="st1" d="M60.001,32c0.001,15.461-12.539,28-28.003,28C16.538,60,4,47.462,4.001,32.002C3.997,16.537,16.536,4,31.996,4
                    C47.46,4,60,16.537,60.001,32L60.001,32z M54.001,32C54,19.851,44.144,10,31.996,10c-12.145,0-21.999,9.85-21.995,22.001
                    C10,44.146,19.852,54,31.998,54C44.146,54,54.002,44.145,54.001,32L54.001,32z"></path>
              </svg>
              <div><h6 className="mb-0">Plans</h6><small className="text-muted">Purchase Plans</small></div>
            </Link>
          </li>
          <li>
            <Link className={currentRoute === '/user/withdrawals' ? 'm-link active' : 'm-link'} to="/user/withdrawals">
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 38 38">
              <path xmlns="http://www.w3.org/2000/svg" d="M20,25c0-1.698,0-6.334,0-11c0-4.418-1.582-8-6-8c-2.083,0-4.072,0.888-5.538,2.335  C5.708,11.053,4,14.826,4,19c0,8.284,6.716,15,15,15c2.736,0,5.294-0.745,7.503-2.025C22.87,31.719,20,28.698,20,25z" style={{fill:"var(--primary-color)"}} data-st="fill:var(--chart-color4);"></path>
              <path xmlns="http://www.w3.org/2000/svg" className="st0" d="M15,11l-1,0.01c0,0,0,0,0-0.01H15z M22,0.24v2.04C29.95,3.69,36,10.65,36,19c0,4.17-1.52,8.01-4.03,10.97  l-0.02-0.02C30.68,31.22,28.93,32,27,32c-2.79,0-5.2-1.64-6.32-4H24l2-2h-5.92C20.02,25.67,20,25.34,20,25s0.02-0.67,0.08-1H28l2-2  h-9.32c1.12-2.36,3.53-4,6.32-4c1.93,0,3.68,0.78,4.95,2.05l1.41-1.41C31.73,17.01,29.48,16,27,16c-3.91,0-7.25,2.51-8.48,6H16v2  h2.06C18.02,24.33,18,24.66,18,25s0.02,0.67,0.06,1H16v2h2.52c1.23,3.48,4.56,5.99,8.46,6C24.6,35.28,21.88,36,19,36  C9.63,36,2,28.37,2,19c0-6.07,3.2-11.41,8-14.41V6.1C8.24,6.44,6,7.72,6,11c0,2.78,2.64,3.44,4.76,3.97C12.96,15.52,14,15.9,14,17  c0,2.82-2.5,2.99-2.99,3C10.5,19.99,8,19.82,8,17H6c0,3.28,2.24,4.56,4,4.9V24h2v-2.1c1.76-0.341,4-1.62,4-4.9  c0-2.78-2.64-3.44-4.76-3.97C9.04,12.48,8,12.1,8,11c0-2.82,2.5-2.99,3-3c2.81,0,2.99,2.48,3,3h2c0-1.57-0.86-4.42-4-4.91V3.52  C14.13,2.54,16.51,2,19,2c0.34,0,0.67,0.01,1,0.03V0.02C19.67,0.01,19.33,0,19,0C8.52,0,0,8.52,0,19c0,10.48,8.52,19,19,19  c10.48,0,19-8.52,19-19C38,9.54,31.06,1.68,22,0.24z"></path>
            </svg>
              <div><h6 className="mb-0">Withdrawals</h6><small className="text-muted">Withdrawal Requests</small></div>
            </Link>
          </li>
          <li>
            <Link className={currentRoute === '/user/referrals' ? 'm-link active' : 'm-link'} to="/user/referrals">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 64 64">
                <linearGradient id="crp_svg" gradientUnits="userSpaceOnUse" x1="13.876" y1="13.876" x2="50.1249" y2="50.1249">
                  <stop offset="0" className="st2"></stop>
                  <stop offset="1" className="st3"></stop>
                </linearGradient>
                <polygon className="st1" points="50,34 50,30 39.465,30 55.517,20.732 53.518,17.269 37.464,26.537 42.732,17.412 39.268,15.412 
                34,24.536 34,6 30,6 30,24.536 24.732,15.412 21.268,17.413 26.536,26.536 10.483,17.268 8.483,20.732 24.535,30 14,30 14,34 
                24.537,34 8.483,43.269 10.483,46.732 26.536,37.465 21.268,46.589 24.732,48.589 30,39.464 30,58 34,58 34,39.465 39.268,48.589 
                42.732,46.589 37.465,37.465 53.517,46.732 55.517,43.269 39.463,34 	"></polygon>
                <path className="st0" d="M50,36c-2.209,0-4-1.791-4-4s1.791-4,4-4s4,1.791,4,4S52.209,36,50,36z M36,6c0,2.209-1.791,4-4,4
                s-4-1.791-4-4s1.791-4,4-4S36,3.791,36,6z M36,58c0,2.209-1.791,4-4,4s-4-1.791-4-4s1.791-4,4-4S36,55.791,36,58z M18,32
                c0-2.209-1.791-4-4-4s-4,1.791-4,4s1.791,4,4,4S18,34.209,18,32z M44.464,18.412c-1.104,1.913-3.552,2.568-5.464,1.464
                c-1.914-1.104-2.568-3.551-1.465-5.464c1.105-1.913,3.551-2.569,5.465-1.464C44.912,14.052,45.568,16.499,44.464,18.412z
                M11.483,15.536c1.913,1.104,2.569,3.551,1.464,5.464s-3.551,2.568-5.464,1.464S4.915,18.914,6.02,17S9.57,14.432,11.483,15.536z
                M56.517,41.536c1.913,1.104,2.568,3.551,1.464,5.464s-3.551,2.569-5.464,1.465S49.948,44.913,51.053,43
                S54.604,40.432,56.517,41.536z M25,44.124c-1.913-1.104-4.36-0.448-5.465,1.464c-1.104,1.913-0.448,4.36,1.465,5.465
                s4.359,0.448,5.464-1.465C27.568,47.676,26.913,45.229,25,44.124z M26.464,14.412c1.104,1.913,0.448,4.36-1.465,5.464
                s-4.359,0.449-5.464-1.464s-0.449-4.359,1.464-5.464S25.359,12.499,26.464,14.412z M7.483,41.536
                c1.913-1.104,4.36-0.449,5.465,1.464s0.448,4.36-1.465,5.465C9.571,49.569,7.125,48.913,6.02,47S5.57,42.641,7.483,41.536z
                M52.517,15.536c1.913-1.104,4.359-0.449,5.464,1.464s0.45,4.36-1.463,5.464s-4.36,0.448-5.465-1.465S50.604,16.641,52.517,15.536z
                M39,44.124c-1.914,1.104-2.568,3.552-1.465,5.465s3.552,2.568,5.465,1.464s2.568-3.552,1.463-5.465
                C43.359,43.676,40.912,43.02,39,44.124z M38,32c0,3.313-2.687,6-6,6s-6-2.687-6-6s2.687-6,6-6S38,28.687,38,32z"></path>
              </svg>
              <div><h6 className="mb-0">Referrals</h6><small className="text-muted">Reffered Connections</small></div>
            </Link>
          </li>
          <li>
            <Link className={currentRoute === '/user/faq' ? 'm-link active' : 'm-link'} to="/user/faq">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 38 38" width="24px" height="24px">
                  <ellipse cx="19" cy="17" rx="13" ry="9" style={{fill:"var(--primary-color)"}} data-st="fill:var(--primary-color);"></ellipse>
                  <path className="st0" d="M38,17c0,8.3-8.5,15-19,15c-0.6,0-1.3,0-1.9-0.1L11,38v-9.6c0.6,0.3,1.3,0.5,2,0.7v4l3.3-3.3  c0.9,0.1,1.7,0.2,2.7,0.2c9.4,0,17-5.8,17-13c0-7.2-7.6-13-17-13C9.6,4,2,9.8,2,17c0,4.3,2.8,8.1,7,10.5v2.2C3.6,27.1,0,22.4,0,17  C0,8.7,8.5,2,19,2C29.5,2,38,8.7,38,17z M16,18h-4v2h4V18z M16,14h-4v2h4V14z M22,18h2c2.2,0,4-1.8,4-4s-1.8-4-4-4h-4v14h2V18z   M22,12h2c1.1,0,2,0.9,2,2s-0.9,2-2,2h-2V12z"></path>
              </svg>
              <div><h6 className="mb-0">FAQs</h6><small className="text-muted">Frequently Asked Questions</small></div>
            </Link>
          </li>
          <li>
            <Link className={currentRoute === '/user/support' ? 'm-link active' : 'm-link'} to="/user/support">
              <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24px" height="24px" viewBox="0 0 32 32">
                <path d="M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3c1.65,0,3-1.35,3-3  C18.5,14.85,17.15,13.5,15.5,13.5z M15.5,4.5c-6.62,0-12,5.38-12,12s5.38,12,12,12s12-5.38,12-12S22.12,4.5,15.5,4.5z M15.5,24.5  c-4.41,0-8-3.59-8-8s3.59-8,8-8s8,3.59,8,8S19.91,24.5,15.5,24.5z M15.5,13.5c-1.65,0-3,1.35-3,3c0,1.65,1.35,3,3,3  c1.65,0,3-1.35,3-3C18.5,14.85,17.15,13.5,15.5,13.5z" style={{fill:"var(--primary-color)"}} data-st="fill:var(--chart-color4);"></path>
                <path className="st2" d="M15.5,8.5c-4.41,0-8,3.59-8,8s3.59,8,8,8s8-3.59,8-8S19.91,8.5,15.5,8.5z M15.5,19.5c-1.65,0-3-1.35-3-3  c0-1.65,1.35-3,3-3c1.65,0,3,1.35,3,3C18.5,18.15,17.15,19.5,15.5,19.5z" style={{fill:"#FFFFFF"}}></path>
                <path className="st0" d="M15.5,4C8.6,4,3,9.6,3,16.5C3,23.4,8.6,29,15.5,29C22.4,29,28,23.4,28,16.5C28,9.6,22.4,4,15.5,4z M15.5,28  C9.16,28,4,22.84,4,16.5S9.16,5,15.5,5S27,10.16,27,16.5S21.84,28,15.5,28z M15.5,8C10.81,8,7,11.81,7,16.5  c0,4.689,3.81,8.5,8.5,8.5c4.689,0,8.5-3.811,8.5-8.5C24,11.81,20.189,8,15.5,8z M15.5,24C11.36,24,8,20.641,8,16.5  C8,12.36,11.36,9,15.5,9c4.141,0,7.5,3.36,7.5,7.5C23,20.641,19.641,24,15.5,24z M15.5,13c-1.93,0-3.5,1.57-3.5,3.5  c0,1.93,1.57,3.5,3.5,3.5c1.93,0,3.5-1.57,3.5-3.5C19,14.57,17.43,13,15.5,13z M15.5,19c-1.38,0-2.5-1.12-2.5-2.5s1.12-2.5,2.5-2.5  s2.5,1.12,2.5,2.5S16.88,19,15.5,19z" style={{fill:"#4D5152"}}></path>
              </svg>
              <div><h6 className="mb-0">Support</h6><small className="text-muted">Support Enquiries</small></div>
            </Link>
          </li>
        </ul>
        <button type="button" className="btn btn-link sidebar-mini-btn text-muted">
          <span><i className="icofont-bubble-right"></i></span>
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
