import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const AntiMoney = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Anti-Money Laundering (AML) Policy</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <p className="lead">
                                                DCIWallet.com strictly adheres to Anti-Money Laundering (AML) regulations to prevent illicit activities.
                                            </p>
                                            <h5 className="">Know Your Customer (KYC):</h5>
                                            <p>We require users to verify their identity by providing 
                                                necessary documentation to ensure compliance with AML laws.</p>

                                            <h5 className="">Monitoring Transactions:</h5>
                                            <p>We actively monitor transactions for suspicious activity and 
                                                maintain records of our users in accordance with regulatory requirements.</p>

                                            <h5 className="">Reporting Obligations:</h5>
                                            <p> We will report any suspicious activities to relevant authorities as 
                                                required by law.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default AntiMoney;
