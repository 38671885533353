import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { planServices } from '../services/PlanServices';
import { walletServices } from '../services/WalletServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';

const Plans = () => {
  const id = localStorage.getItem('id');
  const [investmentPlans, setInvestmentPlans] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const [walletData, setWalletData] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [investmentAmount, setInvestmentAmount] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [purchasedPlans, setPurchasedPlans] = useState([]);

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await planServices.getAllPlans();
        if (response.status === 200) {
          const data = await response.data;
          setInvestmentPlans(data.investment_plans);
          setPlanTypes(data.plan_types);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching the plans:', error);
      }
    };

    fetchPlans();
  }, []);

  useEffect(() => {
    const fetchPlansByUser = async (userId) => {
      try {
        const response = await planServices.getPlansByUser(userId);
        if (response.status === 200) {
          const data = await response.data;
          setPurchasedPlans(data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error('Error fetching the plans:', error);
      }
    };

    fetchPlansByUser(id);
  }, []);

  useEffect(() => {
    const getWalletDetails = async () => {
      try {
        const walletDetailsResponse = await walletServices.getWalletDetails(id);
        if(walletDetailsResponse.status === 200){
          setWalletData(walletDetailsResponse.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    getWalletDetails();
  },[]);

  const getPlanTypeDetails = (planTypeId) => {
    return planTypes.find((planType) => planType._id === planTypeId);
  };

  const handleInvest = (plan) => {
    setSelectedPlan(plan);
    setIsModalOpen(true);
  };

  const handleInvestmentAmountChange = (event) => {
    setInvestmentAmount(event.target.value);
  };

  const handleActivatePlan = () => {
    const { start_price, end_price } = selectedPlan;

    if (investmentAmount < start_price || investmentAmount > end_price) {
      alert(`Investment amount must be between ${start_price} and ${end_price}.`);
      return;
    }

    if(investmentAmount > walletData.balance) {
      alert('Insufficient balance in your wallet. Please topup your wallet before purchasing any plan.')
    } else {
      const purchase = async () => {
        try {
          const data = {
            user_id: id,
            plan_id: selectedPlan?._id,
            amount_paid: investmentAmount,
          }
          const purchasePlanResponse = await planServices.purchasePlan(data);
          if(purchasePlanResponse.status === 201){
            const transactionData = {
              user_id: id, 
              transaction_type: "debit", 
              amount: investmentAmount, 
              description: "Investment Plan Purchased", 
              plan_id: selectedPlan?._id
            }
            const createTransaction = await walletServices.createWalletTransaction(transactionData);
            if(createTransaction.status === 201){
              const balanceData = {
                amount: investmentAmount,
                transaction_type: 'debit'
              }
              const updateUserWalletBalance = await walletServices.updateWalletBalance(id, balanceData);
              if(updateUserWalletBalance.status === 200){
                toast.success('Plan Purchased and activated successfully!');
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      };
      purchase();
    }

    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="body d-flex py-3">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Plans</h3>
              </div>
            </div>
          </div>
          <div className="row align-item-center">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-header py-3 bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold">Purchase Plans</h6>
                </div>
                <div className="row g-2 justify-content-center align-items-center">
                  {investmentPlans && investmentPlans.map((plan) => {
                    const planTypeDetails = getPlanTypeDetails(plan.plan_type_id._id);

                    const isPlanPurchased = purchasedPlans.some(purchasedPlan => purchasedPlan.plan_id === plan._id);

                    return (
                      <div className="col-xxl-3 col-xl-4 col-lg-4 col-sm-12" key={plan._id}>
                        <div className="card">
                          <div className="card-body">
                            <h5>{planTypeDetails.plan_title}</h5>
                            <small className="text-muted">For {planTypeDetails.duration_in_days} days</small>
                            <br /><br />
                            <span className="h2">${plan.start_price} - ${plan.end_price}</span><br />
                            <small>Profit: {plan.profit}%</small>
                            <br /><br />
                            <div className="d-grid my-3">
                              {isPlanPurchased ? (
                                <button className="btn btn-outline-success btn-block" disabled>
                                  Active
                                </button>
                              ) : (
                                <button className="btn btn-outline-primary btn-block" onClick={() => handleInvest(plan)}>
                                  Invest
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={isModalOpen} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Invest in {selectedPlan?.plan_type_id?.plan_title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedPlan && (
              <>
                <p>Min Investment: {selectedPlan.start_price}</p>
                <p>Max Investment: {selectedPlan.end_price}</p>
  
                <div className="form-group">
                  <label htmlFor="investmentAmount">Enter Investment Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    id="investmentAmount"
                    value={investmentAmount}
                    onChange={handleInvestmentAmountChange}
                    placeholder={`Enter amount between ${selectedPlan.start_price} and ${selectedPlan.end_price}`}
                  />
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
            <Button variant="success" onClick={handleActivatePlan}>
              Activate Plan
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default Plans;
