import React from 'react';

const FAQ = () => {
    return (
        <>
        <div className="body d-flex py-3">
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="border-0 mb-4">
                <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                  <h3 className="fw-bold mb-0">FAQs</h3>
                </div>
              </div>
            </div>
            <div className="row align-item-center">
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-header py-3 bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold">Frequently Asked Questions</h6>
                  </div>
                  <div className="card-body">
                    <h6 className="mb-0 fw-bold">1. Verification</h6>
                    <p>Cryptocurrency is a type of digital or virtual currency that uses cryptography for security. Unlike traditional currencies, cryptocurrencies operate on decentralized networks, based on blockchain technology. Popular examples include Bitcoin (BTC), Ethereum (ETH), and Binance Coin (BNB).</p>
                    
                    <h6 className="mb-0 fw-bold">2. How do I deposit funds into my account?</h6>
                    <p>You can deposit funds via various methods:<br />
                        <b>Cryptocurrency:</b> Send cryptocurrency to your wallet address provided after logging into your account.<br />
                        <b>Fiat Currency:</b> Use bank transfers, credit cards, or other supported payment methods to deposit traditional currency.
                    </p>
                    <h6 className="mb-0 fw-bold">3. What are the fees for trading?</h6>
                    <p>Our platform charges a small fee for each trade, which is calculated based on the transaction size. Fee rates may vary depending on your trading volume and the type of trade (maker or taker). Refer to our "Fee Schedule" for more details</p>
                    <h6 className="mb-0 fw-bold">4. How do I withdraw funds from my account?</h6>
                    <p>To withdraw funds, follow these steps:<br />
                        <ul>
                            <li>Go to the "Withdraw" section of your account.</li>
                            <li>Enter the necessary withdrawal details (e.g., wallet address, bank account info).</li>
                            <li>Confirm your request and submit.</li>
                            <li>Withdrawal requests may take up to 24 hours for processing.</li>
                        </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default FAQ;
