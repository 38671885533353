const endpoints = {
  tokenEndPoints:{
    userInfo: "/user/details",
    userEdit: "/user/edit",
    getAllPlans:"/plan/getall",
    addPlan: "/add-plan",
    getPlanById:"/plan/details",
    deletePlanById: "/plan/delete",
    editPlan:"/plan/edit",
    planStatus:"/plan/status",
    supportEnquires: "/user/support-enquiries",
    addSupportRequest: "/user/add-support-request",
    getWithdrawls: "/user/get-withdrawals",
    addWithdrawls: "/user/add-withdrawal-request",
    walletDetails: "/wallet",
    walletTransactions: "/wallet-transactions",
    createWalletTransaction: "/create-wallet-transactions",
    updateWalletBalance: "/wallet/update-balance",
    purchasePlan: "/plans/purchase",
    getPurchasedPlansByUser: "/plans",
  },
  tokenLessEndPoints:{
    login:"/user/login",
    findByEmail:"/user/find-by-email",
    addUser: "/user/add-user",
  }
  };
  
  export default endpoints;
  