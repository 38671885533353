import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const Questions = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader/>
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Frequently Asked Questions</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">

                                            <h5 className="">What is DCIWallet.com?</h5>
                                            <p>DCIWallet.com is a cryptocurrency investment platform that allows 
                                            individuals to pool their funds and invest in large-scale projects, including 
                                            government initiatives and scientific research, as well as opportunities in 
                                            the crypto market.</p>

                                            <h5 className="">How does DCIWallet.com work?</h5>
                                            <p>Users create an account, choose an investment plan, deposit their 
                                            cryptocurrency, and our team invests the pooled funds in high-potential 
                                            projects aimed at generating significant returns.</p>

                                            <h5 className="">What types of investments can I make through DCIWallet.com?</h5>
                                            <p> You can invest in a variety of plans that encompass government projects, 
                                            scientific advancements, land acquisitions, and diverse options within 
                                            the cryptocurrency market.</p>

                                            <h5 className="">What investment plans are available?</h5>
                                            <p>DCIWallet.com offers multiple investment plans tailored to no risk 
                                            profiles and expected returns. These plans accommodate both short
                                            term and long-term investment strategies</p>

                                            <h5 className="">What is the minimum investment required?</h5>
                                            <p>The minimum investment amount may vary based on the plan you 
                                            choose. Please refer to our website for specific details on each 
                                            investment plan.</p>

                                            <h5 className="">Are my investments safe with DCIWallet.com?</h5>
                                            <p>Yes, DCIWallet.com employs advanced security measures and 
                                            technologies to protect your funds and personal information from 
                                            unauthorized access.</p>

                                            <h5 className="">What are the risks associated with investing through DCIWallet.com?</h5>
                                            <p>Unlike with any investment, there are inherent risks, including market 
                                            volatility but with DCIWallet it’s all risk free. You are getting assured 
                                            returns on your investments.</p>

                                            <h5 className="">How do I create an account on DCIWallet.com?</h5>
                                            <p>Simply visit our website and follow the sign-up instructions. You'll need to 
                                            provide some personal information and verify your identity.</p>

                                            <h5 className="">Can I withdraw my funds at any time?</h5>
                                            <p>Yes, you can withdraw your funds; however, depending on the investment 
                                            plan you choose, there may be specific terms regarding withdrawal 
                                            periods. Please refer to the details of each plan.</p>

                                            <h5 className="">How can I monitor my investment performance?</h5>
                                            <p>DCIWallet.com provides a user-friendly dashboard where you can track 
                                            your investments, view real-time updates, and access performance 
                                            reports.</p>

                                            <h5 className="">What is your Privacy Policy?</h5>
                                            <p>Our Privacy Policy explains how we collect, use, and safeguard your 
                                            personal information. You can read it in detail on our website.</p>

                                            <h5 className="">What is your Anti-Money Laundering (AML) Policy?</h5>
                                            <p>DCIWallet.com adheres to stringent AML regulations to prevent illegal 
                                            activities. We conduct thorough KYC (Know Your Customer) checks and 
                                            monitor transactions for any suspicious activities.</p>

                                            <h5 className="">How does DCIWallet.com protect my data?</h5>
                                            <p>We implement advanced security measures, including encryption, 
                                            firewalls, and secure servers, to ensure your data and funds remain safe.</p>

                                            <h5 className="">How can I contact DCIWallet.com customer support?</h5>
                                            <p>You can reach our customer support team through our website's contact 
                                            form, email, or messaging centre from the dashboard.</p>

                                            <h5 className="">Where can I find the latest news and updates about DCIWallet.com?</h5>
                                            <p>For the latest news, blog posts, and updates, you can check the 
                                            corresponding sections on our website.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default Questions;
