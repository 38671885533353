import axiosInstance from "./AxiosConfig";
import endpoints from "./endpoints";

export const withdrawlServices = {
  addWithdrawlRequest: (userData) =>
    axiosInstance.post(endpoints.tokenEndPoints.addWithdrawls, userData),

  getWithdrawls: (id) =>
    axiosInstance.get(`${endpoints.tokenEndPoints.getWithdrawls}/${id}`),
};
