import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const Career = () => {
  return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Careers</h2>
                            <p className="lead">At DCIWallet.com, we are driven by our mission to democratize cryptocurrency 
                                investments and empower individuals to achieve their financial goals. As we continue to 
                                grow and innovate, we are looking for talented individuals to join our team and help 
                                shape the future of finance.</p>
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <p className="lead">
                                                Current Openings:
                                            </p>
                                            <h5 className="">Chat Support Specialist:</h5>
                                            <p>We are seeking enthusiastic and customer-focused individuals to join our 
                                                chat support team. You will be the first point of contact for our users, 
                                                assisting them with inquiries, providing solutions, and ensuring a 
                                                seamless experience on our platform. Strong communication skills and a 
                                                passion for helping others are essential.</p>

                                            <h5 className="">Backend Developer:</h5>
                                            <p>Join our tech team as a Backend Developer to support and enhance our 
                                                platform’s infrastructure. You will work on the architecture and server
                                                side logic that powers DCIWallet.com, ensuring scalability and security. 
                                                Proficiency in programming languages, databases, and server 
                                                management is required.</p>

                                            <h5 className="">Communication Specialist:</h5>
                                            <p>We are looking for a Communication Specialist to develop and implement 
                                                effective communication strategies that promote our brand and engage 
                                                our audience. Strong writing skills, experience in digital marketing, and a 
                                                deep understanding of the cryptocurrency space will help you succeed in 
                                                this role.</p>

                                            <h5 className="">Data Analyst:</h5>
                                            <p>
                                            Our Data Analytics team is crucial for making informed decisions and 
                                            driving our growth. As a Data Analyst, you will analyze trends, generate 
                                            insights, and provide actionable recommendations to enhance our 
                                            operations and platform performance. Analytical skills, proficiency in 
                                            data analysis tools, and an aptitude for problem-solving are essential. 
                                            </p>
                
                                            <br />
                
                                            <p className="lead">Why Work With Us?</p>
                                            <h5 className="">Innovative Environment:</h5>
                                            <p>Be part of a cutting-edge industry that is evolving and 
                                                changing the financial landscape.</p>
                                            
                                            <h5 className="">Growth Opportunities:</h5>
                                            <p>At DCIWallet.com, we value personal and professional 
                                                development and provide opportunities for advancement.</p>

                                            <h5 className="">Collaborative Team:</h5>
                                            <p>Work alongside passionate and talented individuals who 
                                                are committed to making a difference in the world of investments.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <p className="lead">If you are excited about the possibilities in the cryptocurrency space and are eager to 
                                contribute to a forward-thinking company, we invite you to apply for one of our open 
                                positions. Join us in our mission to empower individuals through innovative investment 
                                opportunities at DCIWallet.com</p>
                            <p>How to Apply</p>
                            <span>To apply, please send your resume and a cover letter detailing your 
                                relevant experience and why you want to be part of DCIWallet.com to info@dciwallet.com.
                                We look forward to welcoming you to our team!</span>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default Career;
