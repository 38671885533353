import React, { useEffect, useState } from 'react';
import { walletServices } from '../services/WalletServices';
import { planServices } from '../services/PlanServices';

const Wallet = () => {
    const [walletData, setWalletData] = useState({});
    const [walletTransactionData, setWalletTransactionData] = useState({});
    const id = localStorage.getItem('id');
    const [purchasedPlans, setPurchasedPlans] = useState([]);
    
    useEffect(() => {
        getWalletDetails();
        getWalletTransactions();
    },[]);

    useEffect(() => {
        const fetchPlansByUser = async (userId) => {
            try {
                // Fetch purchased plans by user
                const response = await planServices.getPlansByUser(userId);
                if (response.status === 200) {
                    const purchasedPlans = await response.data;
    
                    // Map over purchasedPlans and fetch each plan's details using plan_id
                    const enrichedPlans = await Promise.all(
                        purchasedPlans.map(async (purchasedPlan) => {
                            try {
                                const planResponse = await planServices.getPlanById(purchasedPlan.plan_id);
                                if (planResponse.status === 200) {
                                    const planData = await planResponse.data;
                                    
                                    // Combine the purchasedPlan and planData
                                    return { ...purchasedPlan, planDetails: planData };
                                } else {
                                    console.error(planResponse.data.message);
                                    return purchasedPlan; // Return the original if plan details fetch fails
                                }
                            } catch (error) {
                                console.error('Error fetching plan details:', error);
                                return purchasedPlan; // Return the original if plan details fetch fails
                            }
                        })
                    );
    
                    // Update the state with enriched plans
                    setPurchasedPlans(enrichedPlans);
                    console.log(enrichedPlans); // Log the combined array
                } else {
                    console.error(response.data.message);
                }
            } catch (error) {
                console.error('Error fetching the plans:', error);
            }
        };
    
        // Call the fetchPlansByUser function on component mount
        fetchPlansByUser(id);
    }, [id]);

    const getWalletDetails = async () => {
        try {
            const walletDetailsResponse = await walletServices.getWalletDetails(id);
            if(walletDetailsResponse.status === 200){
                setWalletData(walletDetailsResponse.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    const getWalletTransactions = async () => {
        try {
            const walletTransactionResponse = await walletServices.getWalletTransactions(id);
            if(walletTransactionResponse.status === 200){
                setWalletTransactionData(walletTransactionResponse.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div id="cryptoon-layout" className="theme-orange">
            <div className="main px-lg-4 px-md-4">
                <div className="body-header border-bottom d-flex py-3">
                    <div className="container-xxl">
                        <div className="row align-items-center">
                            <div className="col">
                                <h1 className="h4 mt-1">Wallet Overview</h1>
                            </div>
                            <div className="col-auto">
                                <button
                                    className="btn btn-dark lift"
                                    data-bs-toggle="modal"
                                    data-bs-target="#topupwallet"
                                >Topup Wallet</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body d-flex py-3">
                    <div className="container-xxl">
                        <div className="row g-3 mb-3 row-deck">
                            <div className="col-xl-12 col-xxl-7">
                                <div className="card">
                                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                                        <h6 className="mb-0 fw-bold">Balance Details</h6> 
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="tab-pane fade show active" id="Spot">
                                                <div className="row g-3">
                                                    <div className="col-lg-6">
                                                        <div>Account balance:</div>
                                                        <h3>{walletData.balance}</h3>
                                                        <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">Total Earned Amount</div>
                                                        <h5>{walletData.total_earned}</h5>
                                                        <div className="mt-3 text-uppercase text-muted small">Total Spent Amount</div>
                                                        <h5>{walletData.total_spent}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-12 col-xxl-5">
                                <div className="card">
                                    <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                                        <h6 className="mb-0 fw-bold">Current Plans</h6> 
                                    </div>
                                    <div className="card-body">
                                        <div className="row g-3 mb-3">
                                            <div className="col-sm-12">
                                                {purchasedPlans.map((plan, index) => (
                                                    <div key={index} className="d-flex justify-content-between flex-wrap">
                                                        <div>
                                                            <div className="truncated">{plan.planDetails.plan_type_id.plan_title}</div>
                                                            <div className="text-muted truncated">
                                                                Invested Amount: {plan.planDetails.start_price} ~ {plan.planDetails.end_price}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card no-bg">
                                    <div className="card-header py-3 d-flex justify-content-between">
                                        <h6 className="mb-0 fw-bold">Transaction History</h6> 
                                    </div>
                                    <div className="card-body">
                                        <table id="ordertabthree" className="priceTable table table-hover custom-table table-bordered align-middle mb-0" style={{width:"100%"}}>
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Type</th>
                                                    <th>Asset</th>
                                                    <th>Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {walletTransactionData.length > 0 ? (
                                                    walletTransactionData.map((transaction) => (
                                                        <tr key={transaction._id}>
                                                            <td>{new Date(transaction.created_at).toLocaleString()}</td>

                                                            <td>{transaction.transaction_type === 'credit' ? 'Credit' : 'Debit'}</td>

                                                            <td>{transaction.plan_id ? (transaction.description ? transaction.description : 'N/A') : 'N/A'}</td>

                                                            <td>{transaction.amount.toFixed(2)}</td>

                                                            <td>
                                                                <span className="color-price-up">Completed</span>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="5">No transactions found</td>
                                                    </tr>
                                                )}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="topupwallet"
                    tabIndex="-1"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title fw-bold" id="expeditLabel1111">
                                    {" "}
                                    Top Up Wallet
                                </h5>
                                <button
                                    type="button"
                                    className="btn-close"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="wallet-address-section">
                                    <label className="form-label">Wallet Address</label>
                                    <div className="input-group mb-3">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="walletAddress"
                                            value="0x1234abcd5678efgh9012ijkl3456mnop7890qrst"
                                            readOnly
                                        />
                                        <button
                                            className="btn btn-outline-secondary"
                                            type="button"
                                            id="copyButton"
                                            onClick={() => {
                                                navigator.clipboard.writeText(
                                                    document.getElementById("walletAddress").value
                                                );
                                                alert("Wallet address copied to clipboard!");
                                            }}
                                        >
                                            <i className="bi bi-clipboard"></i> Copy
                                        </button>
                                    </div>
                                </div>

                                <div className="payment-instructions mt-4">
                                    <h6>How to Initiate a Payment</h6>
                                    <ol>
                                        <li>Copy the wallet address by clicking the "Copy" button.</li>
                                        <li>Go to your crypto wallet or exchange platform.</li>
                                        <li>Initiate a transfer and paste the copied wallet address.</li>
                                        <li>Select the amount you wish to top up and confirm the transaction.</li>
                                        <li>Wait for the confirmation of your transfer on the blockchain.</li>
                                        <li>After transaction completion, share the transaction details with screenshot on <span className='text-primary'>info@dciwallet.com.</span></li>
                                    </ol>
                                </div>

                                <div className="crypto-note mt-3">
                                    <p className="text-danger fw-bold">
                                        Note: We only accept payments in USDT, Bitcoin, and other cryptocurrencies. 
                                        Payments in any other currency, including fiat currency, are not accepted.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Wallet;