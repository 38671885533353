import axios from 'axios';
import { toast } from 'react-toastify';
const tokenlessEndpoints = [
    "/user/login","/user/find-by-email"
]

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    // Check if the request is to a tokenless endpoint
    const isTokenlessEndpoint = tokenlessEndpoints.some((endpoint) =>
      config.url.includes(endpoint)
    );

    if (!isTokenlessEndpoint) {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }

    // ✅ Dynamically set Content-Type
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data"; // FormData handling
    } else if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json"; // Default to JSON
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


// Response interceptor to handle errors
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    let errorMessage = 'An unexpected error occurred';
    if (response) {
        switch (response.status) {
            case 400:
              errorMessage = 'Bad Request';
              break;
            case 401:
              errorMessage = 'Unauthorized Access';
              break;
            case 403:
              errorMessage = 'Session expired. Please log in again.';
              localStorage.clear(); 
              window.location.href = '/login';
              break;
            case 404:
              errorMessage = 'Resource not found';
              break;
            case 500:
              errorMessage = 'Internal Server Error';
              break;
            default:
              errorMessage = 'An unexpected error occurred';
              break;
          }
    } else {
      toast.error('Network Error');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
