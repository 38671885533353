import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../services/AuthServices';
import blogimg3 from '../assets/blogs/blog3.jpeg';
import WebHeader from '../components/WebHeader';
import WebFooter from '../components/WebFooter';

const Blog3 = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Choosing the Right Investment Plan at DCIWallet.com</h2> 
                            <p>01-04-2025 by admin</p>
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <div className='d-flex'>
                                            <img src={blogimg3} className="img-fluid card-img-top me-4 mb-2" alt="..." style={{width: "35%"}}/>
                                            <p className="lead">
                                                Choosing a plan that aligns with your financial goals is vital in your investment
                                                journey. At DCIWallet.com, we understand that every investor's needs are
                                                unique, which is why we offer a variety of investment plans tailored to
                                                different objectives and timelines, all designed around secure capital
                                                deployment. Unlike traditional crypto trading strategies that can be
                                                unpredictable, our options are carefully curated from stable, revenuegenerating projects that focus on consistent growth.
                                                When deciding on the right investment plan, consider factors such as your
                                                return expectations and investment horizon. Here’s how to guide your
                                                decision:</p>
                                            </div>
                                            <p className='lead'>
                                                <ul>
                                                    <li>
                                                        <h5>Return Expectations:</h5> Understand what kind of returns you anticipate.
                                                    Whether you're seeking moderate returns over a short term or aiming for
                                                    substantial growth over a longer period, our investment plans cater to a
                                                    variety of return profiles. Each plan is designed to maximize your
                                                    potential for earnings while adhering to our commitment to security.</li>
                                                    <li>
                                                        <h5>Investment Horizon:</h5> Think about how long you plan to keep your funds
                                                        invested. We offer both short-term and long-term plans, giving you the
                                                        flexibility to choose based on your future financial objectives. Our longterm plans are particularly focused on compounding growth, allowing
                                                        your investments to flourish over time.
                                                    </li>
                                                </ul>
                                            </p>

                                            <p className="lead">
                                                At DCIWallet.com, it's important to highlight that our investment plans
                                                operate under a strict maturity policy. Funds will remain secured until the
                                                maturity date of your chosen plan. Should you decide to withdraw funds
                                                before maturity, kindly note that only a portion of your original investment
                                                will be available for withdrawal, as a penalty will apply to the remaining
                                                funds due to our commitment to preserving the integrity of the investment
                                                structure.
                                            </p>

                                            <p className="lead">
                                                Our dedicated team of investment specialists is here to guide you throughout
                                                the selection process. We take the time to understand your financial
                                                aspirations, ensuring that you find the perfect investment plan to help you
                                                achieve your goals confidently.
                                            </p>

                                            <p className="lead">
                                                Once you choose the right plan, you'll have access to our portfolio
                                                management tools and market insights, designed to keep you informed and
                                                engaged with your investments. At DCIWallet.com, we are committed to
                                                empowering you in the world of cryptocurrency investing, providing a secure
                                                and prosperous future.
                                                Explore our diverse investment plans today and find the one that best aligns
                                                with your financial journey!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default Blog3;
