import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../services/AuthServices';
import blogimg9 from '../assets/blogs/blog9.jpeg';
import WebHeader from '../components/WebHeader';
import WebFooter from '../components/WebFooter';

const Blog9 = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Security Measures at DCIWallet.com: Protecting Your Investments</h2> 
                            <p>01-04-2025 by admin</p>
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <div className='d-flex'>
                                            <img src={blogimg9} className="img-fluid card-img-top me-4 mb-2" alt="..." style={{width: "35%"}}/>
                                            <p className="lead">
                                                In the world of investments, security is paramount—especially in the
                                                cryptocurrency arena. At DCIWallet.com, we take your security seriously by
                                                implementing cutting-edge measures to protect your data and funds. Our
                                                robust systems include two-factor authentication, advanced encryption
                                                protocols, and secure server architecture to ensure that your information
                                                remains confidential, and your investments are safeguarded against potential
                                                threats.
                                            </p>
                                            </div>
                                            <p className="lead">
                                                Moreover, our commitment to regulatory compliance, including Anti-Money
                                                Laundering (AML) and Know Your Customer (KYC) policies, further reinforces
                                                the legitimacy of all transactions on our platform. By investing with
                                                DCIWallet.com, you're not just securing financial growth; you're engaging
                                                with a platform that prioritizes the safety of your assets. Experience peace of
                                                mind knowing that behind every investment lies a strong foundation of
                                                security dedicated to protecting and facilitating your financial journey.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default Blog9;
