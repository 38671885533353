import axiosInstance from "./AxiosConfig";
import endpoints from "./endpoints";

export const authService = {
  login: (credentials) => 
    axiosInstance.post(endpoints.tokenLessEndPoints.login, credentials),
  
  register: (userData) => 
    axiosInstance.post(endpoints.tokenLessEndPoints.addUser, userData),

  checkUser:(userData)=>
    axiosInstance.post(endpoints.tokenLessEndPoints.findByEmail, userData),

  userInfo:(id)=>
    axiosInstance.get(`${endpoints.tokenEndPoints.userInfo}/${id}`),
  userEdit:(id,data)=>
    axiosInstance.patch(`${endpoints.tokenEndPoints.userEdit}/${id}`,data)
};