import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { API_URL } from "../global_constants";
import { authService } from '../services/AuthServices';

const Signup = () => {
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const { id } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const fetchUser = async () => {
    try {
      const data = {
        email:email
      }
      const response = await authService.checkUser(data);
      console.log(response);
    } catch (error) {
      console.error('Failed to fetch user:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
  
    if (password !== confirmPassword) {
      return setError('Passwords do not match.');
    }
  
    try {
      const data = {
        fullName : fullName,
        email : email,
        phoneNumber : phoneNumber,
        password : password,
      }
      await authService.register(data);
      
      setSuccess('You registered successfully with us. Please login and proceed now.');
      setTimeout(() => {
        navigate('/user/login');
      }, 3000);
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'There are some errors in the fields. Please check again.';
      setError(errorMessage);
    }
  };
  

  return (
      <div id="cryptoon-layout" className="theme-orange">
        <div className="main p-2 py-3 p-xl-5">
          <div className="body d-flex p-0 p-xl-5">
              <div className="container-xxl">
                  <div className="row g-3">
                      <div className="col-lg-12 d-flex justify-content-center align-items-center auth-h100">
                          <div className="d-flex flex-column">
                              <h1>Create Your Account</h1>
                              <span className="text-muted">Register yourself with us.</span>
                              <div className="tab-content mt-4 mb-3">
                                  <div className="tab-pane fade show active" id="Email">
                                      <div className="card">
                                          <div className="card-body p-4">
                                              <form onSubmit={handleSubmit}>
                                                <div className="row">
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label fs-6">Full Name</label>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            onChange={(e) => setFullName(e.target.value)}
                                                            placeholder="Enter your name"
                                                            required
                                                        />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="mb-3">
                                                      <label className="form-label fs-6">Email address</label>
                                                      <input 
                                                          type="email" 
                                                          className="form-control"
                                                          onChange={(e) => setEmail(e.target.value)}
                                                          onBlur={fetchUser}
                                                          placeholder="Enter your email address"
                                                          required 
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                  <div className="mb-3">
                                                      <label className="form-label fs-6">Phone Number</label>
                                                      <input 
                                                          type="number" 
                                                          className="form-control"
                                                          onChange={(e) => setPhoneNumber(e.target.value)}
                                                          placeholder="Enter your phone number"
                                                          required 
                                                      />
                                                  </div>
                                                  <div className="row">
                                                    <div className="col-md-6">
                                                      <div className="mb-3">
                                                        <label className="form-label fs-6">Password</label>
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            onChange={(e) => setPassword(e.target.value)}
                                                            placeholder="Enter your passcode"
                                                            required
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="mb-3">
                                                        <label className="form-label fs-6">Confirm Password</label>
                                                        <input 
                                                            type="password" 
                                                            className="form-control" 
                                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                                            placeholder="Confirm your passcode"
                                                            required
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">Create Account</button>
                                              </form> 
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              {error && <p style={{ color: 'red' }}>{error}</p>}
                              {success && <p style={{ color: 'green'}}>{success}</p>}
                              <Link to="/user/signin" title="#">Already registered? <span className="text-secondary text-decoration-underline">Log In</span></Link>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
  );
};

export default Signup;
