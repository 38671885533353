import axiosInstance from "./AxiosConfig";
import endpoints from "./endpoints";

export const planServices = {
  getAllPlans: () => 
    axiosInstance.get(endpoints.tokenEndPoints.getAllPlans),
  purchasePlan: (planData) => 
    axiosInstance.post(endpoints.tokenEndPoints.purchasePlan, planData),

  getPlansByUser: (userId) =>
    axiosInstance.get(`${endpoints.tokenEndPoints.getPurchasedPlansByUser}/${userId}`),

  getPlanById: (id) =>
    axiosInstance.get(`${endpoints.tokenEndPoints.getPlanById}/${id}`),
};