import React from 'react';
import DataTable from 'react-data-table-component';

const Referrals = () => {

  const columns = [
    {
      name: 'Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Phone',
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => (
        <span className={`badge ${row.status === 'Active' ? 'bg-success' : 'bg-danger'}`}>
          {row.status}
        </span>
      ),
      sortable: true,
    },
  ];

  const data = [
    { name: 'Molly', email: 'molly@gmail.com', phone: '1234567890', status: 'Not Active' },
    { name: 'Brian', email: 'brian@gmail.com', phone: '1234567890', status: 'Active' },
    { name: 'Julia', email: 'julia@gmail.com', phone: '1234567890', status: 'Active' },
    { name: 'Sonia', email: 'sonia@gmail.com', phone: '1234567890', status: 'Not Active' },
    { name: 'Adam H', email: 'adam@gmail.com', phone: '1234567890', status: 'Not Active' },
    { name: 'Alexander', email: 'alexander@gmail.com', phone: '1234567890', status: 'Active' },
    { name: 'Gabrielle', email: 'gabrielle@gmail.com', phone: '1234567890', status: 'Active' },
    { name: 'Grace', email: 'grace@gmail.com', phone: '1234567890', status: 'Active' },
    { name: 'Ryan', email: 'ryan@gmail.com', phone: '1234567890', status: 'Not Active' },
    { name: 'Christian', email: 'christian@gmail.com', phone: '1234567890', status: 'Active' },
  ];

  return (
    <>
      <div className="body d-flex py-3">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Referrals List</h3>
              </div>
            </div>
          </div>
          <div className="row align-item-center">
            <div className="col-md-12">
              <div className="card mb-3">
                <div className="card-header py-3 bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold">My Referrals</h6>
                </div>
                <div className="card-body">
                <DataTable
                  title="My Project Table"
                  columns={columns}
                  data={data}
                  pagination
                  striped
                  responsive
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Referrals;
