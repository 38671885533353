import { React, useEffect, useState, useContext, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { API_URL } from "../global_constants";
import { GetUserData} from '../components/UserContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import { supportServices } from '../services/SupportServices';

const Support = () => {

    const [enquiriesData, setEnquiryData] = useState([]);
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const modalRef = useRef(null);
    const encodedData = localStorage.getItem('user');
    const userData = JSON.parse(decodeURIComponent(encodedData));
  
    const userId = localStorage.getItem('id');

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
  
      return `${day}-${month}-${year}`;
    };

    const fetchEnquiryData = async () => {
      try {
        const response = await supportServices.fetchSupportEnquires(userId);
        if(response.status === 200){
          const data = response.data;
          setEnquiryData(data);
        }else{
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        console.log(false);
      }
    };

    useEffect(() => {
      if (userId) {
        fetchEnquiryData();
      }
    }, [userId]);

    const columns = [
      {
        name: 'Ticket ID',
        selector: row => row.ticket_id,
        sortable: true,
      },
      {
        name: 'Subject',
        selector: row => row.subject,
        sortable: true,
      },
      {
        name: 'Status',
        selector: row => row.status,
        cell: row => (
          row.status === 'pending' ? (
            <span className="badge bg-warning">Pending</span>
          ) : row.status === 'in_progress' ? (
            <span className="badge bg-info">In Progress</span>
          ) : row.status === 'on_hold' ? (
            <span className="badge bg-danger">On Hold</span>
          ) : row.status === 'completed' ? (
            <span className="badge bg-success">Completed</span>
          ) : (
            <span className="badge bg-secondary">Pending</span>
          )
        ),
        sortable: true,
      },
      {
        name: 'Last Update',
        selector: row => formatDate(row.updatedAt),
        sortable: true,
      }
    ];

    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        const data = {
          user_id: userId,
          subject: subject,
          message: message,
        }
        const response = await supportServices.addSupportRequest(data);
        if(response.status === 201){
          toast.success('We received your enquiry. We will contact you soon!');
  
          const modalElement = modalRef.current;
          const modalInstance = bootstrap.Modal.getInstance(modalElement); 
          modalInstance.hide();
    
          const backdrop = document.querySelector('.modal-backdrop');
          if (backdrop) {
            backdrop.remove();
          }
    
          fetchEnquiryData();
        }else{
          toast.error(response.data.message);
        }
      } catch (error) {
        const errorMessage = error.response?.data?.message || 'There are some errors in the fields. Please check again.';
        toast.error(errorMessage);
      }
    }

    return (
        <>
        <div className="body d-flex py-3">
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="border-0 mb-4">
                <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                  <h3 className="fw-bold mb-0">Support</h3>
                  <Link to="/admin/users/create" className="btn btn-primary step-btn" data-bs-toggle="modal" data-bs-target="#addEnquiry">+ Add Ticket</Link>
                </div>
              </div>
            </div>
            <div className="row align-item-center">
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-header py-3 bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold">Support Enquiries</h6>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns}
                      data={enquiriesData}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addEnquiry" tabIndex="-1" aria-hidden="true" ref={modalRef}>
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title  fw-bold" id="expeditLabel1111"> New Support Ticket</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <div className="deadline-form">
                  <form>
                    <div className="row g-3 mb-3">
                      <div className="col-sm-12">
                        <label htmlFor="subject" className="form-label">Subject</label>
                        <input 
                          type="text" 
                          className="form-control" 
                          id="subject"
                          onChange={(e) => setSubject(e.target.value)}
                        />
                      </div>
                      <div className="col-sm-12">
                        <label htmlFor="message" className="form-label">Message</label>
                        <textarea className="form-control" id="message" rows="3" spellCheck="false" onChange={(e) => setMessage(e.target.value)}></textarea>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                <button onClick={handleSubmit} type="button" className="btn btn-primary">Save</button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default Support;
