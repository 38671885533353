import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const AboutUs = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">About Us</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <p className="lead">
                                                Welcome to <b>DCIWallet.com</b>, where we empower individuals to unlock the potential of 
                                                their cryptocurrency investments. Our mission is to democratize access to high-yield 
                                                investment opportunities traditionally reserved for the wealthiest investors.
                                            </p>
                                            <p className="lead">
                                                At <b>DCIWallet.com</b>, we believe that everyone, regardless of their financial background, 
                                                deserves the chance to grow their wealth through innovative and secure investment 
                                                avenues. Our platform serves as a bridge connecting regular investors with large-scale 
                                                projects that can generate significant returns. 
                                            </p>
                                            <p className="lead">
                                                Our approach is simple yet powerful: we pool funds from our investors and channel 
                                                them into ambitious initiatives, including government projects, cutting-edge scientific 
                                                research, substantial land acquisitions, and prospects in the dynamic cryptocurrency 
                                                market. By joining our community, you are not only investing in your future but also 
                                                participating in transformative projects that can shape economies and communities. 
                                            </p>
                                            <p className="lead">
                                                We are committed to transparency, security, and responsible investing. With our robust 
                                                technology and dedicated team of financial experts, we strive to provide you with the 
                                                tools and guidance necessary to make informed investment decisions. Join us and be a 
                                                part of a financial revolution that puts the power of investment in your hands.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default AboutUs;
