import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { GetUserData } from '../components/UserContext';
import DonutChart from '../components/DonutChart';
import { walletServices } from '../services/WalletServices';
import { planServices } from '../services/PlanServices';
import InvestmentProgress from '../components/InvestmentProgress';

const Dashboard = () => {
  const [walletData, setWalletData] = useState({});
  const [walletTransactionData, setWalletTransactionData] = useState([]);
  const id = localStorage.getItem('id');
  const [purchasedPlans, setPurchasedPlans] = useState([]);
  const [investmentPlans, setInvestmentPlans] = useState([]);
  const [planTypes, setPlanTypes] = useState([]);
  const userData = GetUserData();
  const sparklineData = [47, 45, 54, 38, 56, 47, 45, 54, 38, 56, 38, 56, 24, 65, 31];
  const [randomPlans, setRandomPlans] = useState([]);
  const [investedAmount] = useState(1000); // Initial invested amount
  const [investmentGrowth] = useState(1200); // Example: current growth
  
  const [progressData, setProgressData] = useState([]);

  
  useEffect(() => {
      getWalletDetails();
      getWalletTransactions();
  },[]);
  
    useEffect(() => {
      const fetchPlansByUser = async (userId) => {
          try {
              // Fetch purchased plans by user
              const response = await planServices.getPlansByUser(userId);
              if (response.status === 200) {
                  const purchasedPlans = await response.data;
  
                  // Map over purchasedPlans and fetch each plan's details using plan_id
                  const enrichedPlans = await Promise.all(
                    purchasedPlans && purchasedPlans.map(async (purchasedPlan) => {
                          try {
                              const planResponse = await planServices.getPlanById(purchasedPlan.plan_id);
                              if (planResponse.status === 200) {
                                  const planData = await planResponse.data;
                                  
                                  // Combine the purchasedPlan and planData
                                  return { ...purchasedPlan, planDetails: planData };
                              } else {
                                  console.error(planResponse.data.message);
                                  return purchasedPlan; // Return the original if plan details fetch fails
                              }
                          } catch (error) {
                              console.error('Error fetching plan details:', error);
                              return purchasedPlan; // Return the original if plan details fetch fails
                          }
                      })
                  );
  
                  // Update the state with enriched plans
                  setPurchasedPlans(enrichedPlans);
              } else {
                  console.error(response.data.message);
              }
          } catch (error) {
              console.error('Error fetching the plans:', error);
          }
      };
  
      // Call the fetchPlansByUser function on component mount
      fetchPlansByUser(id);
  }, [id]);

    useEffect(() => {
      const fetchPlans = async () => {
        try {
          const response = await planServices.getAllPlans();
          if (response.status === 200) {
            const data = await response.data;
            setInvestmentPlans(data.investment_plans);
            setPlanTypes(data.plan_types);
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error('Error fetching the plans:', error);
        }
      };
  
      fetchPlans();
    }, []);

        const getStoredPlans = () => {
            const storedPlans = JSON.parse(localStorage.getItem('randomPlans'));
            const storedDate = localStorage.getItem('randomPlansDate');
            const today = new Date().toISOString().split('T')[0]; // Get today's date as YYYY-MM-DD
    
            if (storedPlans && storedDate === today) {
                return storedPlans; // Return stored plans if they exist and the date is today
            }
    
            return null;
        };
    
        const shufflePlans = () => {
            const shuffledPlans = [...investmentPlans].sort(() => 0.5 - Math.random());
            return shuffledPlans.slice(0, 4);
        };
    
        useEffect(() => {
            const storedPlans = getStoredPlans();
    
            if (storedPlans) {
                setRandomPlans(storedPlans); // Use stored plans
            } else {
                const newRandomPlans = shufflePlans(); // Shuffle and pick 4 new random plans
                setRandomPlans(newRandomPlans);
    
                // Store the new plans and the current date in localStorage
                localStorage.setItem('randomPlans', JSON.stringify(newRandomPlans));
                localStorage.setItem('randomPlansDate', new Date().toISOString().split('T')[0]); // Store today's date
            }
        }, [investmentPlans]);

  const getWalletDetails = async () => {
      try {
          const walletDetailsResponse = await walletServices.getWalletDetails(id);
          if(walletDetailsResponse.status === 200){
              setWalletData(walletDetailsResponse.data);
          }
      } catch (error) {
          console.error(error);
      }
  }

  const getWalletTransactions = async () => {
      try {
          const walletTransactionResponse = await walletServices.getWalletTransactions(id);
          if(walletTransactionResponse.status === 200){
              setWalletTransactionData(walletTransactionResponse.data);
          }
      } catch (error) {
          console.error(error);
      }
  }

    const calculateProgress = (plan) => {
      const { amount_paid, planDetails } = plan;
      const profitPercentage = planDetails.profit;
      const durationInDays = planDetails.plan_type_id.duration_in_days;
  
      const calculateGrowth = (daysElapsed) => {
        return amount_paid + (amount_paid * profitPercentage * (daysElapsed / durationInDays)) / 100;
      };
  
      const today = new Date();
      const purchaseDate = new Date(plan.purchase_date);
      const timeElapsedInDays = Math.floor((today - purchaseDate) / (1000 * 60 * 60 * 24));
      const progress = Math.min((timeElapsedInDays / durationInDays) * 100, 100);
      
      const investmentGrowth = calculateGrowth(Math.min(timeElapsedInDays, durationInDays));
  
      return {
        progress,
        investmentGrowth,
      };
    };
  
    useEffect(() => {
      if(purchasedPlans){
      const updatedData = purchasedPlans.map((plan) => {
        const { progress, investmentGrowth } = calculateProgress(plan);
        return { plan, progress, investmentGrowth };
      });
  
      setProgressData(updatedData);
    }
    }, [purchasedPlans]);

  return (
    <>
      <div className="body d-flex py-3"> 
        <div className="container-xxl">
          <div className="row g-3 mb-3">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row g-3 align-items-center">
                    <div className="col-md-6 col-lg-6 col-xl-3">
                      <div className="d-flex">
                        <img className="avatar rounded-circle" src="../assets/images/profile_av.svg" alt="profile" />
                        <div className="flex-fill ms-3">
                          <p className="mb-0"><span className="font-weight-bold">{userData?.fullName}</span></p>
                          <small className="">{userData?.email}</small>
                        </div>
                      </div>
                    </div>
                    {userData?.kyc_status === "pending" && (
                      <>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <Link to="/user/profile" title="Complete the KYC" className="btn btn-primary text-dark mb-1">Please complete your KYC!</Link>
                        </div>
                      </>
                    )}
                    {userData?.kyc_status === "in_progress" && (
                      <>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <Link to="/user/profile" title="KYC Confirmation is in progress." className="btn btn-info text-dark mb-1">KYC confirmation is in progress!</Link>
                        </div>
                      </>
                    )}
                    {userData?.kyc_status === "completed" && (
                      <>
                        <div className="col-md-6 col-lg-6 col-xl-4">
                          <Link to="/user/profile" title="KYC Confirmation is in progress." className="btn btn-success text-dark mb-1">KYC is completed!</Link>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3 row-cols-1 row-cols-md-2 row-cols-lg-4">
            {progressData && progressData.map((data, index) => (
              <div className="col" key={index}>
                <div className="card">
                  <div className="card-body d-flex align-items-center">
                    <div className="flex-fill text-truncate">
                      <span className="text-muted small text-uppercase">
                        {data.plan.planDetails.plan_type_id.plan_title}
                      </span>
                      <div className="d-flex flex-column">
                        <div className="price-block">
                          <span className="fs-6 fw-bold color-price-up">{data.investmentGrowth.toFixed(2)}</span>
                          <span className="small text-muted px-2">
                            ${data.investmentGrowth.toFixed(2)}
                          </span>
                        </div>
                        <div className="price-report">
                          <span className="small text-success">
                            + {((data.investmentGrowth - data.plan.amount_paid) / data.plan.amount_paid * 100).toFixed(2)}%{' '}
                            <i className="fa fa-level-up"></i>
                          </span>
                          <span className="small text-muted px-2">Amount Paid: ${data.plan.amount_paid}</span>
                        </div>
                      </div>
                      <InvestmentProgress
                        investedAmount={data.plan.amount_paid}
                        startDate={data.plan.purchase_date}
                        periodInDays={data.plan.planDetails.plan_type_id.duration_in_days}
                        promisedReturn={data.plan.planDetails.profit}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="row g-3 mb-3 row-deck">
            <div className="col-xl-12 col-xxl-7">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom align-items-center flex-wrap">
                  <h6 className="mb-0 fw-bold">Balance Details</h6> 
                  <ul className="nav nav-tabs tab-body-header rounded d-inline-flex mt-2 mt-md-0" role="tablist">
                    <li className="nav-item"><Link className="nav-link active" data-bs-toggle="tab" to="#Spot" role="tab">Spot</Link></li>
                  </ul>
                </div>
                <div className="card-body">
                  <div className="tab-content">
                    <div className="tab-pane fade show active" id="Spot">
                      <div className="row g-3">
                        <div className="col-lg-6">
                          <div>Account balance:</div>
                          <h3>{walletData.balance}</h3>
                          <div className="mt-3 pt-3 text-uppercase text-muted pt-2 small">Total Earned Amount</div>
                          <h5>{walletData.total_earned}</h5>
                          <div className="mt-3 text-uppercase text-muted small">Total Spent Amount</div>
                          <h5>{walletData.total_spent}</h5>
                        </div>
                        <div className="col-lg-6">
                          <DonutChart
                            series={[
                              walletData.balance || 0, 
                              walletData.total_earned || 0, 
                              walletData.total_spent || 0
                            ]}
                            labels={['Balance', 'Total Earned', 'Total Spent']}
                            colors={['var(--chart-color1)', 'var(--chart-color2)', 'var(--chart-color3)']}
                            id="apex-simple-donut"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-xxl-5">
              <div className="card">
                <div className="card-header py-3 d-flex flex-wrap justify-content-between align-items-center bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold">Today's Top Plans</h6>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-nowrap mb-0">
                      <tbody>
                        {randomPlans && randomPlans.map((plan, index) => (
                            <tr key={plan._id}>
                                <td>
                                    <i className={`fa fa-square chart-text-color${index + 1}`}></i> {plan.plan_type_id.plan_title}
                                </td>
                                <td>
                                    {plan.start_price} ~ {plan.end_price} USD
                                </td>
                                <td>
                                    <span className={`badge ${plan.profit > 0 ? 'bg-success' : 'bg-danger'}`}>
                                        {plan.profit > 0 ? `+${plan.profit}%` : `${plan.profit}%`}
                                    </span>
                                </td>
                            </tr>
                        ))}
                      </tbody>
                  </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3 mb-3 row-deck">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between">
                  <h6 className="mb-0 fw-bold">Recent Transactions</h6> 
                </div>
                <div className="card-body">
                  <table id="ordertabthree" className="priceTable table table-hover custom-table-2 table-bordered align-middle mb-0" style={{width: '100%' }}>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Type</th>
                        <th>Asset</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                    {walletTransactionData && walletTransactionData.length > 0 ? (
                          walletTransactionData.map((transaction) => (
                              <tr key={transaction._id}>
                                  <td>{new Date(transaction.created_at).toLocaleString()}</td>
                                  <td>{transaction.transaction_type === 'credit' ? 'Credit' : 'Debit'}</td>
                                  <td>{transaction.plan_id ? (transaction.description ? transaction.description : 'N/A') : 'N/A'}</td>
                                  <td>{transaction.amount.toFixed(2)}</td>
                                  <td><span className="color-price-up">Completed</span></td>
                              </tr>
                          ))
                      ) : (
                          <tr><td colSpan="5">No transactions found</td></tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;