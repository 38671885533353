import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../services/AuthServices';
import iconimg from "../assets/header_logo.png";

const WebFooter = () => {
    return (
        <div className="section footer color-bg-100 pb-0" style={{paddingTop: "45px"}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-12">
                        <div className="d-flex align-items-center">
                            <h4 className="mb-4 fw-bold "><img src={iconimg} width={100}/></h4>
                        </div>
                        <span className="lead">DCI Wallet is the world's leading motion for investing crypto in fixed plans.</span>
                        <ul className="mt-3 fs-6 list-unstyled">
                            <li><span>Address:</span> 2071 Eva Pearl Street,LA</li>
                            <li><span>Email:</span> example@pw.com</li>
                            <li><span>Call:</span> 225-747-3640</li>
                        </ul>
                    </div>
                    <div className="col-lg-8 col-12">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-12">
                                <h5 className="mb-4">Investing</h5>
                                <ul className="footer-link px-3">
                                    <li className="my-1"><Link to="#">Wallet</Link></li>
                                    <li className="my-1"><Link to="/faqs">FAQs</Link></li>
                                    <li className="my-1"><Link to="/investment-plans">Investment Plans</Link></li>
                                    <li className="my-1"><Link to="/blogs">Blogs</Link></li>
                                </ul>
                            </div>
                            <div className="col-lg-4 col-md-6 col-12">
                                <h5 className="mb-4">About Us</h5>
                                <ul className="footer-link px-3">
                                    <li className="my-1"><Link to="/about-us">About Us</Link></li>
                                    <li className="my-1"><Link to="#">Business Contact</Link></li>
                                    <li className="my-1"><Link to="/career">Career</Link></li>
                                    <li className="my-1"><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    <li className="my-1"><Link to='/terms-and-conditions'>Terms And Conditions</Link></li>
                                    <li className="my-1"><Link to="/anti-money-laundering-policy">Anti-Money Laundering Policy</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    );
}

export default WebFooter;
