import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const PrivacyPolicy = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Privacy Policy</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <p className="lead">
                                                At DCIWallet.com, we prioritize your privacy. This Privacy Policy outlines our practices regarding the collection, use, and protection of your personal information. We are committed to safeguarding your data and ensuring compliance with applicable privacy laws.
                                            </p>
                                            <h5 className="">Information We Collect:</h5>
                                            <p>We may collect personal information such as your name, email 
                                            address, phone number, and financial details when you create an account.</p>

                                            <h5 className="">Usage of Information:</h5>
                                            <p>We use your information to provide services, enhance user 
                                                experience, communicate with you, and comply with legal obligations.</p>

                                            <h5 className="">Protection of Information:</h5>
                                            <p>We implement robust security measures to protect your data 
                                                from unauthorized access.</p>

                                            <h5 className="">Cookies</h5>
                                            <p> Our website may use cookies to enhance your browsing experience. You can 
                                                manage your cookie preferences through your browser settings. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default PrivacyPolicy;
