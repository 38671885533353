import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { API_URL } from "../global_constants";
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { authService } from '../services/AuthServices';
import styled from 'styled-components';

const Header = () => {

    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');

    const navigate = useNavigate();

    const handleLogout = async () => {
        localStorage.removeItem('token');
        localStorage.removeItem('id');
        localStorage.removeItem('user');
        window.location.reload();
        navigate('/user/signin');

        try {
            const id = localStorage.getItem('id');
            if (!id) {
                console.log('test');
            }

            const response = await authService.userInfo(id);

            setFullName(response.data.fullName);
            setEmail(response.data.email);
        } catch (error) {
            console.error('Failed to fetch user:', error);
        }
    };

  return (
    <HeaderWrapper>
        <div className="header">
            <nav className="navbar py-4">
                <div className="container-xxl">
                    <div className="h-right d-flex align-items-center mr-5 mr-lg-0 order-1">
                        <div className="d-flex header-wallet-icon">
                            <Link className="nav-link text-primary collapsed" to="/user/wallet" title="Wallet">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30px" height="30px" viewBox="0 0 64 64">
                                    <path className="st1" d="M15,24c-3.86,0-7,2.691-7,6v20c0,3.309,3.14,6,7,6h41V32H15c-1.598,0-3-0.935-3-2s1.402-2,3-2h5.25 c0,0,1-5,5.75-5s6,5,6,5h22v-4H15z"/>
                                    <path className="st0" d="M42,4c-4.418,0-8,3.582-8,8s3.582,8,8,8c4.417,0,8-3.582,8-8S46.417,4,42,4z M42,16c-2.208,0-4-1.792-4-4 s1.792-4,4-4s4,1.792,4,4S44.208,16,42,16z"/>
                                    <path className="st0" d="M26,20c-4.418,0-8,3.582-8,8h4c0-2.208,1.792-4,4-4s4,1.792,4,4h4C34,23.582,30.418,20,26,20z"/>
                                </svg>
                            </Link>
                        </div>
                        <div className="dropdown notifications zindex-popover">
                            <Link className="nav-link dropdown-toggle pulse" to="#" role="button" data-bs-toggle="dropdown">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25px" height="25px" viewBox="0 0 38 38">
                                    <path d="M36,34v-2h-2.98c-0.598-0.363-1.081-3.663-1.4-5.847c-0.588-4.075-1.415-9.798-4.146-13.723  C26.584,12.154,25.599,12,24.5,12c-3.646,0-5.576,1.657-7.106,4.086C15.089,19.746,14,30.126,14,33c0,2.757,2.243,5,5,5  c2.414,0,4.435-1.721,4.898-4H36z" style={{ fill: 'var(--primary-color)' }} data-st="fill:var(--chart-color4);"></path>
                                    <path className="st0" d="M33.02,32c-0.598-0.363-1.081-3.663-1.4-5.847c-0.851-5.899-2.199-15.254-9.101-17.604  C23.433,7.643,24,6.386,24,5c0-2.757-2.243-5-5-5s-5,2.243-5,5c0,1.386,0.567,2.643,1.482,3.549  c-6.902,2.35-8.25,11.705-9.101,17.604C6.209,27.324,5.991,28.813,5.733,30h2.042c0.192-0.961,0.376-2.127,0.586-3.562  C9.36,19.501,10.73,10,19,10c8.27,0,9.64,9.501,10.641,16.442c0.386,2.636,0.682,4.394,1.108,5.558H2v2h12.101  c0.464,2.279,2.485,4,4.899,4c2.415,0,4.435-1.721,4.899-4H36v-2H33.02z M19,8c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3  S20.654,8,19,8z M19,36c-1.304,0-2.416-0.836-2.829-2h5.658C21.416,35.164,20.304,36,19,36z" ></path>
                                </svg>
                                <span className="pulse-ring"></span>
                            </Link>
                            <div id="NotificationsDiv" className="dropdown-menu rounded-lg shadow border-0 dropdown-animation dropdown-menu-md-end p-0 m-0">
                                <div className="card border-0 w380">
                                    <div className="card-header border-0 p-3">
                                        <h5 className="mb-0 font-weight-light d-flex justify-content-between">
                                            <span>Notifications</span>
                                            <span className="badge text-white">6</span>
                                        </h5>
                                    </div>
                                    <div className="tab-content card-body">
                                        <div className="tab-pane fade show active">
                                            <ul className="list-unstyled list mb-0">
                                                <li className="py-2 mb-1 border-bottom">
                                                    <Link to="#" className="d-flex">
                                                        <img className="avatar rounded-circle" src="../assets/images/xs/avatar1.svg" alt="" />
                                                        <div className="flex-fill ms-2">
                                                            <p className="d-flex justify-content-between mb-0 "><span className="font-weight-bold">Chloe Walkerr</span> <small>2MIN</small></p>
                                                            <span className="">Added New Ico Coin 2021-08-25 <span className="badge bg-success">Add</span></span>
                                                        </div>
                                                    </Link>
                                                </li>
                                                <li className="py-2 mb-1 border-bottom">
                                                    <Link to="#" className="d-flex">
                                                        <div className="avatar rounded-circle no-thumbnail">AH</div>
                                                        <div className="flex-fill ms-2">
                                                            <p className="d-flex justify-content-between mb-0 "><span className="font-weight-bold">Alan	Hill</span> <small>13MIN</small></p>
                                                            <span className="">Invoice generator </span>
                                                        </div>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Link className="card-footer text-center border-top-0" to="#">View all notifications</Link>
                                </div>
                            </div>
                        </div>
                        <Dropdown className="user-profile ml-2 ml-sm-3 d-flex align-items-center zindex-popover">
                            <Dropdown.Toggle as="a" className="nav-link dropdown-toggle pulse p-0">
                                <img className="avatar lg rounded-circle img-thumbnail" src="../assets/images/profile_av.svg" alt="profile" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="rounded-lg shadow border-0 dropdown-animation dropdown-menu-end p-0 m-0">
                                <div className="card border-0 w280">
                                    <div className="card-body pb-0">
                                        <div className="d-flex py-1">
                                            <img className="avatar rounded-circle" src="../assets/images/profile_av.svg" alt="profile" />
                                            <div className="flex-fill ms-3">
                                                <p className="mb-0"><span className="font-weight-bold">{fullName}</span></p>
                                                <small className="">{email}</small>
                                            </div>
                                        </div>
                                        <div><hr className="dropdown-divider border-dark" /></div>
                                    </div>
                                    <div className="list-group m-2">
                                        <Dropdown.Item as={Link} to="/user/profile">
                                            Profile
                                        </Dropdown.Item>
                                        <Dropdown.Item as="button" onClick={handleLogout}>
                                            Signout
                                        </Dropdown.Item>
                                    </div>
                                </div>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <button className="navbar-toggler p-0 border-0 menu-toggle order-3" type="button" data-bs-toggle="collapse" data-bs-target="#mainHeader">
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="order-0 col-lg-4 col-md-4 col-sm-12 col-12 mb-3 mb-md-0 d-flex align-items-center">
                        <div className="main-search border-start px-3 flex-fill"></div>
                    </div>
                </div>
            </nav>
        </div>
    </HeaderWrapper>
  );
};

export default Header;

const HeaderWrapper = styled.div`
    .zindex-popover{
        z-index: 999 !important;
    }
    .header-wallet-icon, .notifications, .user-profile{
        margin-right: 15px;
    }
    .dropdown-toggle::after{
        display: none;
    }
`;