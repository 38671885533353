import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';
import blogimg1 from '../../assets/blogs/blog1.jpeg';
import blogimg2 from '../../assets/blogs/blog2.jpg';
import blogimg3 from '../../assets/blogs/blog3.jpeg';
import blogimg4 from '../../assets/blogs/blog4.jpeg';
import blogimg5 from '../../assets/blogs/blog5.jpeg';
import blogimg6 from '../../assets/blogs/blog6.jpeg';
import blogimg7 from '../../assets/blogs/blog7.jpeg';
import blogimg8 from '../../assets/blogs/blog8.jpeg';
import blogimg9 from '../../assets/blogs/blog9.jpeg';
import blogimg10 from '../../assets/blogs/blog10.jpeg';

const Blogs = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Blogs</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-deck">
                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg1} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>The Future of Cryptocurrency Investing</h5>
                                                <Link to="/the-future-of-cryptocurrency-investing" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg2} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>Understanding Investment Opportunities in Crypto</h5>
                                                <Link to="/understanding-investment-opportunities-in-crypto" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg3} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>Choosing the Right Investment Plan at DCIWallet.com</h5>
                                                <Link to="/choosing-the-right-investment-plan-at-dciwallet" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg4} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>The Importance of Diversification in Your Investment Strategy</h5>
                                                <Link to="/the-importance-of-diversification-in-your-investment-strategy" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg5} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>The Role of Government Projects in Your Investments</h5>
                                                <Link to="/the-role-of-government-projects-in-your-investments" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg6} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>Understanding the Technology Behind Your Investments</h5>
                                                <Link to="/understanding-the-technology-behind-your-investments" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg7} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>The Benefits of Using DCIWallet.com for Your Investments</h5>
                                                <Link to="/the-benefits-of-using-dciwallet-for-your-investments" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg8} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>The Evolution of Cryptocurrency: A Historical Perspective</h5>
                                                <Link to="/the-evolution-of-cryptocurrency" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                    <div className="col">
                                        <div className="card border-0 position-relative">
                                            <img src={blogimg9} className="img-fluid card-img-top" alt="..."/>
                                            <div className="card-body">
                                                <h5>Security Measures at DCIWallet.com: Protecting Your Investments</h5>
                                                <Link to="/security-measures-at-dciwallet" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                            </div> 
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default Blogs;
