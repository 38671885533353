import axiosInstance from "./AxiosConfig";
import endpoints from "./endpoints";

export const walletServices = {
  getWalletDetails: (userId) => 
    axiosInstance.get(`${endpoints.tokenEndPoints.walletDetails}/${userId}`),

  getWalletTransactions: (userId) =>
    axiosInstance.get(`${endpoints.tokenEndPoints.walletTransactions}/${userId}`),

  createWalletTransaction: (transactionData) =>
    axiosInstance.post(endpoints.tokenEndPoints.createWalletTransaction, transactionData),

  updateWalletBalance: (userId, balanceData) =>
    axiosInstance.put(`${endpoints.tokenEndPoints.updateWalletBalance}/${userId}`, balanceData),
};