import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { API_URL } from "../global_constants";
import { GetUserData } from "../components/UserContext";
import { authService } from "../services/AuthServices";

const Profile = () => {
  const encodedData = localStorage.getItem("user");
  const userData = JSON.parse(decodeURIComponent(encodedData));

  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [address_line_1, setAddressLine1] = useState("");
  const [address_line_2, setAddressLine2] = useState("");
  const [addressState, setAddressState] = useState("");
  const [country, setCountry] = useState("");

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    identificationDocument: null,
    identificationNumber: "",
    proofOfAddressDocument: null,
    selfieUpload: null,
    occupation: "",
    sourceOfFunds: "",
  });

  const fetchUser = async () => {
    try {
      const id = localStorage.getItem("id");

      if (!id) {
        return;
      }

      const response = await authService.userInfo(id);

      setFullName(response.data.fullName);
      setEmail(response.data.email);
      setDateOfBirth(response.data.dateOfBirth);
      setPhoneNumber(response.data.phoneNumber);
      setAddressLine1(response.data.address_line_1);
      setAddressLine2(response.data.address_line_2);
      setAddressState(response.data.addressState);
      setCountry(response.data.country);
    } catch (error) {
      console.error("Failed to fetch user:", error);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const id = localStorage.getItem("id");
    try {
      const data = {
        fullName: fullName,
        phoneNumber: phoneNumber,
        email: email,
        dateOfBirth: dateOfBirth,
        address_line_1: address_line_1,
        address_line_2: address_line_2,
        addressState: addressState,
        country: country,
      };

      await authService.userEdit(id,data);
      window.location.reload();
    } catch (error) {
      console.error("Failed to save deails:", error);
    }
  };

  const handleChange = (e) => {
    const { id, value, files } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [id]: files ? files[0] : value,
    }));
  };

  const kycSubmit = async (e) => {
    e.preventDefault();

    const id = localStorage.getItem("id");
    let user = localStorage.getItem("user");

    console.log(formData);
    console.log(formData.identificationDocument);
    console.log(formData.proofOfAddressDocument);
    console.log(formData.selfieUpload);


    const data = new FormData();
    data.append("identificationDocument", formData.identificationDocument);
    data.append("identificationNumber", formData.identificationNumber);
    data.append("proofOfAddressDocument", formData.proofOfAddressDocument);
    data.append("selfieUpload", formData.selfieUpload);
    data.append("occupation", formData.occupation);
    data.append("sourceOfFunds", formData.sourceOfFunds);
    data.append("kyc_status", "in_progress");

    try {
      const response = await authService.userEdit(id,data);

      if (user) {
        let user = localStorage.getItem("user");
        const decodedUser = decodeURIComponent(user);
        const parsedUser = JSON.parse(decodedUser);
        parsedUser.kyc_status = "in_progress";
        localStorage.setItem("user", JSON.stringify(parsedUser));
      }

      toast.success(
        "KYC details are uploaded successfully. Please wait for the confirmation."
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="body d-flex py-3">
        <div className="container-xxl">
          <div className="row align-items-center">
            <div className="border-0 mb-4">
              <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                <h3 className="fw-bold mb-0">Profile</h3>
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-xl-12">
              <div className="card">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent align-items-center ">
                  <h6 className="mb-0 fw-bold">Verification</h6>
                </div>
                <div className="card-body">
                  <div className="row g-3">
                    <div className="col-xl-3">
                      <div className="d-flex flex-column">
                        <span className="text-muted my-1">
                          <i className="icofont-check-circled text-success fs-5 px-2"></i>
                          Personal information
                        </span>
                        <span className="text-muted my-1">
                          {userData.identificationDocument_verified ===
                          false ? (
                            <>
                              <i className="icofont-close-circled text-danger fs-5 px-2"></i>
                              Government-issued ID
                            </>
                          ) : (
                            <>
                              <i className="icofont-check-circled text-success fs-5 px-2"></i>
                              Government-issued ID
                            </>
                          )}
                        </span>
                        <span className="text-muted my-1">
                          {userData.selfieUpload_verified === false ? (
                            <>
                              <i className="icofont-close-circled text-danger fs-5 px-2"></i>
                              Facial recognition
                            </>
                          ) : (
                            <>
                              <i className="icofont-check-circled text-success fs-5 px-2"></i>
                              Facial recognition
                            </>
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row g-3">
            <div className="col-xl-4 col-lg-5 col-md-12">
              <div className="card profile-card flex-column mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold ">Profile</h6>
                </div>
                <div className="card-body d-flex profile-fulldeatil flex-column">
                  <div className="profile-user text-center w220 mx-auto">
                    {/* <Link to="#">
                                            <img src="../assets/images/lg/avatar4.svg" alt="" className="avatar xl rounded img-thumbnail shadow-sm" />
                                        </Link> */}
                    <button
                      className="btn btn-primary"
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                      }}
                      data-bs-toggle="modal"
                      data-bs-target="#editprofile"
                    >
                      <i className="icofont-edit"></i>
                    </button>
                    {/* <div className="about-info d-flex align-items-center mt-3 justify-content-center flex-column">
                                            <span className="text-muted small">Admin ID : PXL-0001</span>
                                        </div> */}
                  </div>
                  <div className="profile-info w-100">
                    <h6 className="mb-0 mt-2  fw-bold d-block fs-6 text-center">
                      {fullName}
                    </h6>
                    {/* <span className="py-1 fw-bold small-11 mb-0 mt-1 text-muted text-center mx-auto d-block">24 years, California</span> */}
                    {/* <p className="mt-2">Duis felis ligula, pharetra at nisl sit amet, ullamcorper fringilla mi. Cras luctus metus non enim porttitor sagittis. Sed tristique scelerisque arcu id dignissim.</p> */}
                    <div className="row g-2 pt-2">
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-ui-touch-phone"></i>
                          <span className="ms-2">{phoneNumber}</span>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-email"></i>
                          <span className="ms-2">{email}</span>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-globe-alt"></i>
                          <span className="ms-2">
                            {addressState}, {country}
                          </span>
                        </div>
                      </div>
                      <div className="col-xl-12">
                        <div className="d-flex align-items-center">
                          <i className="icofont-address-book"></i>
                          <span className="ms-2">
                            {address_line_1}, {address_line_2}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-7 col-md-12">
              <div className="card mb-3">
                <div className="card-header py-3 d-flex justify-content-between bg-transparent border-bottom-0">
                  <h6 className="mb-0 fw-bold">KYC Settings</h6>
                </div>
                <div className="card-body">
                  <form className="row g-4" onSubmit={kycSubmit}>
                    <div className="col-md-6">
                      <label
                        htmlFor="identificationDocument"
                        className="form-label"
                      >
                        Identification Document
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="identificationDocument"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="identificationNumber"
                        className="form-label"
                      >
                        Identification Number
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="identificationNumber"
                        value={formData.identificationNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label
                        htmlFor="proofOfAddressDocument"
                        className="form-label"
                      >
                        Proof Of Address
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="proofOfAddressDocument"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="selfieUpload" className="form-label">
                        Selfie Upload
                      </label>
                      <input
                        className="form-control"
                        type="file"
                        id="selfieUpload"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="occupation" className="form-label">
                        Occupation
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="occupation"
                        value={formData.occupation}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="sourceOfFunds" className="form-label">
                        Source Of Funds
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="sourceOfFunds"
                        value={formData.sourceOfFunds}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-12 mt-4">
                      <button
                        type="submit"
                        className="btn btn-primary text-uppercase px-5"
                      >
                        SAVE
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="editprofile"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title  fw-bold" id="expeditLabel1111">
                {" "}
                Edit Profile
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="deadline-form">
                <form>
                  <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="item100" className="form-label">
                        Full Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="item100"
                        value={fullName}
                        onChange={(e) => setFullName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="item100" className="form-label">
                        Date Of Birth
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="item100"
                        value={dateOfBirth}
                        onChange={(e) => setDateOfBirth(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                      <label htmlFor="mailid" className="form-label">
                        Mail
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="mailid"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="phoneid" className="form-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="phoneid"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col-sm-12">
                      <label className="form-label">Address Line 1</label>
                      <input
                        type="text"
                        className="form-control"
                        value={address_line_1}
                        onChange={(e) => setAddressLine1(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col-sm-12">
                      <label className="form-label">Address Line 2</label>
                      <input
                        type="text"
                        className="form-control"
                        value={address_line_2}
                        onChange={(e) => setAddressLine2(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="row g-3 mb-3">
                    <div className="col-sm-6">
                      <label className="form-label">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                      />
                    </div>
                    <div className="col-sm-6">
                      <label htmlFor="abc1" className="form-label">
                        State
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="state"
                        value={addressState}
                        onChange={(e) => setAddressState(e.target.value)}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                onClick={handleSubmit}
                type="button"
                className="btn btn-primary"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
