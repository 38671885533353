import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate  } from 'react-router-dom';
import Layout from './layout/Layout';
import SignIn from './pages/SignIn';
import Dashboard from './pages/Dashboard';
import Signup from './pages/Signup';
import Profile from './pages/Profile';
import PrivateRoute from './components/PrivateRoute';
import Referrals from './pages/Referrals';
import { UserProvider } from './components/UserContext';
import { ToastContainer } from 'react-toastify';
import FAQ from './pages/FAQ';
import Plans from './pages/Plans';
import Withdrawal from './pages/Withdrawal';
import Support from './pages/Support';
import Wallet from './pages/Wallet';
import Home from './pages/webpages/Home';
import Questions from './pages/webpages/Questions';
import Career from './pages/webpages/Career';
import AboutUs from './pages/webpages/AboutUs';
import AntiMoney from './pages/webpages/AntiMoney';
import HowItWorks from './pages/webpages/HowItWorks';
import PrivacyPolicy from './pages/webpages/PrivacyPolicy';
import TnC from './pages/webpages/TnC';
import Blogs from './pages/webpages/Blogs';

import Blog1 from './blogs/Blog1';
import Blog2 from './blogs/Blog2';
import Blog3 from './blogs/Blog3';
import Blog4 from './blogs/Blog4';
import Blog5 from './blogs/Blog5';
import Blog6 from './blogs/Blog6';
import Blog7 from './blogs/Blog7';
import Blog8 from './blogs/Blog8';
import Blog9 from './blogs/Blog9';

const App = () => {
  return (
      <Router>
        <ToastContainer />
        <Routes>
          <Route path="/user/signin" element={<SignIn />} />
          <Route path="/user/signup" element={<Signup />} />
          <Route path="/home" element={<Home />} />
          <Route path="/faqs" element={<Questions />} />
          <Route path="/career" element={<Career />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/anti-money-laundering-policy" element={<AntiMoney />} />
          <Route path="/how-it-works" element={<HowItWorks />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-and-conditions" element={<TnC />} />
          <Route path="/blogs" element={<Blogs />} />

          <Route path="/the-future-of-cryptocurrency-investing" element={<Blog1 />} />
          <Route path="/understanding-investment-opportunities-in-crypto" element={<Blog2 />} />
          <Route path="/choosing-the-right-investment-plan-at-dciwallet" element={<Blog3 />} />
          <Route path="/the-importance-of-diversification-in-your-investment-strategy" element={<Blog4 />} />
          <Route path="/the-role-of-government-projects-in-your-investments" element={<Blog5 />} />
          <Route path="/understanding-the-technology-behind-your-investments" element={<Blog6 />} />
          <Route path="/the-benefits-of-using-dciwallet-for-your-investments" element={<Blog7 />} />
          <Route path="/the-evolution-of-cryptocurrency" element={<Blog8 />} />
          <Route path="/security-measures-at-dciwallet" element={<Blog9 />} />

          <Route element={<Layout />}>
            <Route path="/" element={<Navigate to="/home" />} />
            <Route element={<PrivateRoute />}>
              <Route path="/user/dashboard" element={<Dashboard />} />
              <Route path="/user/profile" element={<Profile />} />
              <Route path="/user/referrals" element={<Referrals />} />
              <Route path="/user/faq" element={<FAQ />} />
              <Route path="/user/plans" element={<Plans />} />
              <Route path="/user/withdrawals" element={<Withdrawal />} />
              <Route path="/user/support" element={<Support />} />
              <Route path="/user/wallet" element={<Wallet />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate to="/user/signin" />} />
        </Routes>
      </Router>
  );
};

export default App;
