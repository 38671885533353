import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const HowItWorks = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">How It Works</h2>
                            <p className="lead">Investing at DCIWallet.com is designed to be simple, straightforward, and secure.</p>
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <h5 className="">Create Your Account:</h5>
                                            <p className="lead">
                                                Sign up on our platform and create a secure account. Our user friendly interface makes it easy for you to navigate through investment options.
                                            </p>

                                            <h5 className="">Choose Your Investment Plan:</h5>
                                            <p className="lead">
                                                Explore our selection of investment plans tailored to  different risk profiles and returns. Whether you’re looking for short-term gains or long term growth, we have options that suit your needs. 
                                            </p>

                                            <h5 className="">Contribute Funds:</h5>
                                            <p className="lead">
                                                Deposit your cryptocurrency into your account. We accept various cryptocurrencies, ensuring a flexible investment approach. 
                                            </p>

                                            <h5 className="">Pooling Resources:</h5>
                                            <p className="lead">
                                                Your investment, along with that of other investors, is pooled together, allowing us to participate in larger funding opportunities that are typically inaccessible to the average investor.
                                            </p>
                                            <h5 className="">Strategic Investments:</h5>
                                            <p className="lead">
                                                Our expert team analyzes and selects promising projects to invest in, including government contracts, groundbreaking scientific initiatives, and lucrative real estate acquisitions. We prioritize ventures that demonstrate potential for high returns.
                                            </p>

                                            <h5 className="">Monitor Your Investment:</h5>
                                            <p className="lead">
                                                Stay updated with real-time insights into your investment performance through our dashboard. We provide regular reports detailing how your funds are being utilized and the progress of each project.
                                            </p>

                                            <h5 className="">Withdraw Your Returns:</h5>
                                            <p className="lead">
                                                Enjoy the benefits of your investment! You can withdraw your returns or reinvest them to maximize your earning potential. Join DCIWallet.com today and take the first step toward empowering your financial future.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default HowItWorks;
