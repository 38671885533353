import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';

const TnC = () => {
    return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section theme-option" id="ThemeOption">
                <div className="container my-3">
                    <div className="row mb-5 justify-content-center">
                        <div className="col-xl-6 col-lg-11 col-md-12 text-center">
                            <h2 className="h1 text-primary">Terms And Conditions</h2> 
                        </div>
                        <div className="row g-3">
                            <div className="col-xl-12">
                                <div className="card ms-xl-5">
                                    <div className="card-body pb-0">
                                        <div className="theme-setting">
                                            <p className="lead">
                                                Welcome to DCIWallet.com. By using our services, you agree to comply with these Terms and Conditions:
                                            </p>
                                            <h5 className="">Service Provision:</h5>
                                            <p>Our platform offers investment opportunities in various projects. You 
                                                acknowledge that investments carry inherent risks, and past performance is not 
                                                indicative of future results.</p>

                                            <h5 className="">Account Responsibility:</h5>
                                            <p>Users are responsible for maintaining the confidentiality of 
                                                their account credentials and for all activities conducted under their account.</p>

                                            <h5 className="">Investment Risks:</h5>
                                            <p>You understand and accept that cryptocurrency investments are 
                                                volatile. Ensure you only invest funds you can afford to lose.</p>

                                            <h5 className="">Limitation of Liability:</h5>
                                            <p> DCIWallet.com shall not be liable for any losses incurred due to 
                                                your investment decisions. 
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WebFooter />
        </div>
    );
};

export default TnC;
