import axiosInstance from "./AxiosConfig";
import endpoints from "./endpoints";

export const supportServices = {
  addSupportRequest: (userData) =>
    axiosInstance.post(endpoints.tokenEndPoints.addSupportRequest, userData),

  fetchSupportEnquires: (id) =>
    axiosInstance.get(`${endpoints.tokenEndPoints.supportEnquires}/${id}`),
};
