import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../../services/AuthServices';
import hero from "../../assets/hero.svg";
import dashboardImg from '../../assets/dhashbord.png';
import blogImg1 from '../../assets/img1.jpg';
import WebHeader from '../../components/WebHeader';
import WebFooter from '../../components/WebFooter';
import blogimg1 from '../../assets/blogs/blog1.jpeg';
import blogimg2 from '../../assets/blogs/blog2.jpg';
import blogimg3 from '../../assets/blogs/blog3.jpeg';

const Home = () => {
  return (
        <div id="mainDiv" className="theme-orange">
            <WebHeader />
            <div className="section hero-area my-5" style={{paddingBottom: "80px"}}>
                <div className="container">
                    <div className="row g-3 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <img src={hero} alt="#" className="img-fluid"/>
                        </div>
                        <div className="col-lg-6 order-lg-1">
                            <div className="inner-content">
                                <div className="hero-text">
                                    <h1>Profiltable Investment <br/>of <strong className="text-uppercase text-primary">CRYPTO</strong><br/> on the DCIWallet</h1>
                                    <div className="lead">Get started with the easiest and most Secure platform to invest your cryptocurrency on a fixed and constant growth of your choice.</div>
                                </div>
                                <button type="button" className="btn btn-secondary text-uppercase my-4 py-2 fs-5 ">Start Investing</button>
                            </div>
                        </div>
                    </div>
                    <div className="row g-3 align-items-center mt-md-3 pt-md-3 mt-lg-5 pt-lg-5">
                        <div className="col">
                            <div className="d-flex align-items-center flex-nowrap">
                                <span className="px-2 lead">How it's Work?</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section exchange" style={{paddingBottom: "80px"}} id="Exchanges">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-xl-6 col-lg-10 col-md-12">
                            <h2 className="h1">Trusted and secure CRYPTO investment <span className="text-primary">PLANS</span></h2> 
                            <p className="lead">DCIWallet is more than just a Crypto wallet. You can store all your CRYPTO to save from market's ups and downs and you can start growing your crypto through our INVESTMENT Plans</p>
                        </div>
                    </div>
                    <div className="row g-3 align-items-center pt-2">
                        <div className="col-xl-12">
                            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="100%" height="300px" viewBox="0 0 38 38">
                                <path xmlns="http://www.w3.org/2000/svg" d="M20,25c0-1.698,0-6.334,0-11c0-4.418-1.582-8-6-8c-2.083,0-4.072,0.888-5.538,2.335  C5.708,11.053,4,14.826,4,19c0,8.284,6.716,15,15,15c2.736,0,5.294-0.745,7.503-2.025C22.87,31.719,20,28.698,20,25z" style={{ fill: "var(--primary-color)" }} data-st="fill:var(--chart-color4);"></path>
                                <path xmlns="http://www.w3.org/2000/svg" className="st0" d="M15,11l-1,0.01c0,0,0,0,0-0.01H15z M22,0.24v2.04C29.95,3.69,36,10.65,36,19c0,4.17-1.52,8.01-4.03,10.97  l-0.02-0.02C30.68,31.22,28.93,32,27,32c-2.79,0-5.2-1.64-6.32-4H24l2-2h-5.92C20.02,25.67,20,25.34,20,25s0.02-0.67,0.08-1H28l2-2  h-9.32c1.12-2.36,3.53-4,6.32-4c1.93,0,3.68,0.78,4.95,2.05l1.41-1.41C31.73,17.01,29.48,16,27,16c-3.91,0-7.25,2.51-8.48,6H16v2  h2.06C18.02,24.33,18,24.66,18,25s0.02,0.67,0.06,1H16v2h2.52c1.23,3.48,4.56,5.99,8.46,6C24.6,35.28,21.88,36,19,36  C9.63,36,2,28.37,2,19c0-6.07,3.2-11.41,8-14.41V6.1C8.24,6.44,6,7.72,6,11c0,2.78,2.64,3.44,4.76,3.97C12.96,15.52,14,15.9,14,17  c0,2.82-2.5,2.99-2.99,3C10.5,19.99,8,19.82,8,17H6c0,3.28,2.24,4.56,4,4.9V24h2v-2.1c1.76-0.341,4-1.62,4-4.9  c0-2.78-2.64-3.44-4.76-3.97C9.04,12.48,8,12.1,8,11c0-2.82,2.5-2.99,3-3c2.81,0,2.99,2.48,3,3h2c0-1.57-0.86-4.42-4-4.91V3.52  C14.13,2.54,16.51,2,19,2c0.34,0,0.67,0.01,1,0.03V0.02C19.67,0.01,19.33,0,19,0C8.52,0,0,8.52,0,19c0,10.48,8.52,19,19,19  c10.48,0,19-8.52,19-19C38,9.54,31.06,1.68,22,0.24z"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section market-trend" style={{paddingBottom: "80px"}} id="anywhere">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-xl-7 col-lg-10 col-md-12">
                            <h2 className="h1">Login <span className="text-primary">Anywhere.</span></h2> 
                            <p className="lead">Compatible with multiple devices, start investing with safety and convenience.</p>
                        </div>
                    </div>
                    <div className="row g-3 align-items-center pt-2">
                        <div className="col-xl-8">
                            <img src={dashboardImg} alt="#" className="img-fluid"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section get-touch" style={{paddingBottom: "80px"}} id="get-touch">
                <div className="container">
                    <div className="row mb-5">
                        <div className="col-xl-7 col-lg-10 col-md-12">
                            <h2 className="h1">Get in touch. <span className="text-primary">Stay in touch.</span></h2> 
                            <p className="lead">We are available round the clock to provide you the best support.</p>
                        </div>
                    </div>
                    <div className="row g-3 align-items-center pt-2">
                        <div className="col-xl-3">
                            <div className="card p-3">
                                <i className="icofont-live-support fs-1 chart-text-color5"></i>
                                <h5 className="my-3">24 / 7 Support</h5>
                                <p>Got a problem? Just get in touch. Our support team is available 24/7.</p>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="card p-3">
                                <i className="icofont-file-document fs-1 chart-text-color5"></i>
                                <h5 className="my-3">Cryptoon Blog</h5>
                                <p>News and updates from the world's leading cryptocurrency exchange.</p>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="card p-3">
                                <i className="icofont-hat fs-1 chart-text-color5"></i>
                                <h5 className="my-3">Careers</h5>
                                <p>Help build the future of technology. Start your new career at Cryptoon.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section news" id="News">
                <div className="container">
                    <div className="row g-3 align-items-end">
                        <div className="col-lg-12 col-xl-12 order-xl-2">
                            <div className="row mb-5 justify-content-end">
                                <div className="col-xl-12 col-lg-12 col-md-12 text-end">
                                    <span className="text-primary fw-bold fs-6 letter-spacing text-uppercase">Latest Blogs</span>
                                    <h2 className="h1">Top cryptocurrency news</h2> 
                                    <Link to="/blogs"><h5>View All</h5></Link>
                                </div>
                            </div>
                            <div className="row g-3 row-cols-1 row-cols-sm-1 row-cols-md-2 row-cols-lg-3 row-deck">
                                <div className="col">
                                    <div className="card border-0 position-relative">
                                        <img src={blogimg1} className="img-fluid card-img-top" alt="..."/>
                                        <div className="card-body">
                                            <h5>The Future of Cryptocurrency Investing</h5>
                                            <Link to="/the-future-of-cryptocurrency-investing" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                        </div> 
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card border-0 position-relative">
                                        <img src={blogimg2} className="img-fluid card-img-top" alt="..."/>
                                        <div className="card-body">
                                            <h5>Understanding Investment Opportunities in Crypto</h5>
                                            <Link to="/understanding-investment-opportunities-in-crypto" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                        </div> 
                                    </div>
                                </div>

                                <div className="col">
                                    <div className="card border-0 position-relative">
                                        <img src={blogimg3} className="img-fluid card-img-top" alt="..."/>
                                        <div className="card-body">
                                            <h5>Choosing the Right Investment Plan at DCIWallet.com</h5>
                                            <Link to="/choosing-the-right-investment-plan-at-dciwallet" className="btn btn-outline-primary rounded mt-2">Read more</Link>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <WebFooter />

        </div>
    );
};

export default Home;
