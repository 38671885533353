import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../services/AuthServices';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.message) {
      toast.success(location.state.message);
    }
  }, [location]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        email: email,
        password:password
      }
      const response = await authService.login(data);
      const userData = response.data.data;
      const jsonString = JSON.stringify(userData);
      const encodedData = encodeURIComponent(jsonString);

      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', encodedData);
      localStorage.setItem('id',response.data.data._id);
      navigate('/user/dashboard');
    } catch (err) {
      setError('Invalid credentials');
    }
  };

  return (
    <div id="cryptoon-layout" className="theme-orange">
      <div className="main p-2 py-3 p-xl-5">
        <div className="body d-flex p-0 p-xl-5">
          <div className="container-xxl">
            <div className="row g-3">
              <div className="col-lg-12 d-flex justify-content-center align-items-center auth-h100">
                <div className="d-flex flex-column">
                  <h1>Account Login</h1>
                  <span className="text-muted">Welcome back! Fill in the credentials to login into the dashboard.</span>
                  <div className="tab-content mt-4 mb-3">
                    <div className="tab-pane fade show active" id="Email">
                      <div className="card">
                        <div className="card-body p-4">
                          <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                              <label className="form-label fs-6">Email address</label>
                              <input 
                                type="email" 
                                className="form-control"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                            <div className="mb-3">
                              <label className="form-label fs-6">Password</label>
                              <input 
                                type="password" 
                                className="form-control"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                            <button type="submit" className="btn btn-primary text-uppercase py-2 fs-5 w-100 mt-2">log in</button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                  {error && <p style={{ color: 'red' }}>{error}</p>}
                  <div style={{display: "flex"}}>
                    <p style={{marginRight: "20px"}}>Don't have an account?</p>
                    <Link to="/user/signup" title="#" className="text-primary text-decoration-underline">Register now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
