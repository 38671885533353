import React from 'react';
import ApexCharts from 'react-apexcharts';

const DonutChart = ({ series, labels, colors, id }) => {
  const options = {
    chart: { height: 250, type: 'donut' },
    dataLabels: { enabled: false },
    legend: {
      position: 'right',
      horizontalAlign: 'center',
      show: true,
    },
    colors,
    series,
    labels,
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: { width: 200 },
          legend: { position: 'bottom' },
        },
      },
    ],
  };

  return <ApexCharts options={options} series={series} type="donut" height={250} />;
};

export default DonutChart;
