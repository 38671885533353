import React, { createContext, useState, useEffect } from 'react';



export const GetUserData = () => {
  const [userData, setUserData] = useState(null);

  const retrieveUserData = () => {
    const encodedData = localStorage.getItem('user');
    if (encodedData) {
      const decodedData = decodeURIComponent(encodedData);
      try {
        const parsedData = JSON.parse(decodedData);
        return parsedData;
      } catch (error) {
        console.error('Error parsing user data:', error);
        return null;
      }
    }
    return null;
  };

  useEffect(() => {
    const data = retrieveUserData();
    if (data) {
      setUserData(data);
    }
  }, []);

  return userData;
};


