import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { authService } from '../services/AuthServices';
import iconimg from "../assets/header_logo.png";

const WebHeader = () => {
    return (
        <nav className="navbar navbar-expand-xl py-lg-3 lp-menu">
            <div className="container py-2 rounded">
                <Link className="navbar-brand fw-bold  fs-3" to="/" title="Logo"><img src={iconimg} width={100}/></Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-expanded="false">
                    <span className="navbar-toggler-icon"><i className="fa fa-bars"></i></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        <li className="nav-item"><Link className="nav-link me-2 page-scroll" to="/how-it-works">How It Works?</Link></li>
                        <li className="nav-item"><Link className="nav-link me-2 page-scroll" to="/user/signin">Investment Plans</Link></li>
                        <li className="nav-item"><Link className="nav-link me-2 page-scroll" to="#get-touch">Get in Touch</Link></li>
                    </ul>
                    <form className="d-flex">
                        <Link to="/user/signin" title="login" className="btn text-uppercase mx-sm-1 py-2">Login</Link>
                        <Link to="/user/signup" title="Register" className="btn btn-primary rounded text-uppercase py-2">Register</Link>
                    </form>
                </div>
            </div>
        </nav>
    );
}

export default WebHeader;
