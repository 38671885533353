// src/components/InvestmentProgress.js
import React, { useEffect, useState } from 'react';
import ApexCharts from 'react-apexcharts';

const InvestmentProgress = ({ investedAmount, startDate, periodInDays, promisedReturn }) => {
  const [progress, setProgress] = useState(0);
  const [investmentGrowthArray, setInvestmentGrowthArray] = useState([{ date: startDate, value: investedAmount }]);

  const calculateProgress = () => {
    const currentDate = new Date();
    const timeElapsed = (currentDate - new Date(startDate)) / (1000 * 60 * 60 * 24); // Convert ms to days

    if (timeElapsed >= periodInDays) {
      setProgress(100);
      const finalGrowth = investedAmount + (investedAmount * promisedReturn) / 100;
      setInvestmentGrowthArray(prev => [...prev, { date: currentDate, value: finalGrowth }]);
    } else {
      const percentTimePassed = (timeElapsed / periodInDays) * 100;
      setProgress(percentTimePassed);
      const newGrowth = investedAmount + (investedAmount * (promisedReturn * (timeElapsed / periodInDays))) / 100;
      setInvestmentGrowthArray(prev => [...prev, { date: currentDate, value: newGrowth }]);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      calculateProgress();
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(interval);
  }, []);

  const chartOptions = {
    chart: {
      type: 'area',
      height: 200, // Adjusted height for full chart
      sparkline: { enabled: false }, // Full chart instead of sparkline
    },
    stroke: {
      width: 1,
    },
    colors: ['#008FFB'],
    fill: {
      opacity: 0.3,
    },
    tooltip: {
      enabled: true,
      y: {
        formatter: (val) => `$${val.toFixed(2)}`,
      },
    },
    xaxis: {
      type: 'datetime',
      labels: {
        format: 'dd/MM',
      },
    },
  };

  // Map the investmentGrowthArray to match the data format required by ApexCharts
  const chartSeries = [
    {
      name: 'Growth',
      data: investmentGrowthArray ? investmentGrowthArray.map((entry) => ({
        x: new Date(entry.date).getTime(), // Date as timestamp for proper plotting
        y: entry.value, // Growth value
      })) : [],
    },
  ];

  return (
    <ApexCharts
      options={chartOptions}
      series={chartSeries}
      type="area"
      height={200} // Adjust the height of the chart
    />
  );
};

export default InvestmentProgress;
