import { React, useEffect, useState, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { API_URL } from "../global_constants";
import { GetUserData } from '../components/UserContext';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as bootstrap from 'bootstrap';
import { withdrawlServices } from '../services/WithdrawlServices';

const Withdrawal = () => {

  const [withdrawData, setWithdrawData] = useState([]);
  const [amount, setAmount] = useState('');
  const [accNo, setAccNo] = useState('');
  const modalRef = useRef(null);
  const encodedData = localStorage.getItem('user');
  const userData = JSON.parse(decodeURIComponent(encodedData));

  const userId = localStorage.getItem('id');

  // Function to fetch withdrawal data
  const fetchWithdrawData = async () => {
    try {
      const response = await withdrawlServices.getWithdrawls(userId);
      if(response.status === 200){
        const data = response.data;
        setWithdrawData(data);
      }else{
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      console.log(false);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchWithdrawData();
    }
  }, [userId]);

  const columns = [
    {
      name: 'Username',
      selector: row => row.username,
      sortable: true,
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
    },
    {
      name: 'Account Number',
      selector: row => row.account_no,
      sortable: true,
    },
    {
      name: 'Phone Number',
      selector: row => row.phoneNumber,
    },
    {
      name: 'Withdrawal Request',
      selector: row => row.withdrawal_request,
      sortable: true,
    },
    {
      name: 'Status',
      selector: row => row.status,
      sortable: true,
      cell: row => <span className={row.status === 'pending' ? 'badge bg-warning' : 'badge bg-success'}>{row.status}</span>,
    },
    {
      name: 'Amount',
      selector: row => `$${row.amount}`,
      sortable: true,
    },
    {
      name: 'Requested At',
      selector: row => new Date(row.createdAt).toLocaleDateString(),
      sortable: true,
    },
    {
      name: 'Updated At',
      selector: row => new Date(row.updatedAt).toLocaleDateString(),
      sortable: true,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const data = {
        user_id: userId,
        username: userData?.fullName,
        email: userData?.email,
        account_no: accNo,
        phoneNumber: userData?.phoneNumber,
        amount: Number(amount),
      }
      const response =  await withdrawlServices.addWithdrawlRequest(data);
      if(response.status === 201){
        toast.success('Request generated successfully. Please wait for the further confirmation!');

        // Close the modal after the request is successful
        const modalElement = modalRef.current;
        const modalInstance = bootstrap.Modal.getInstance(modalElement); 
        modalInstance.hide();
  
        const backdrop = document.querySelector('.modal-backdrop');
        if (backdrop) {
          backdrop.remove();
        }
  
        // Call fetchWithdrawData to update the table after closing the modal
        fetchWithdrawData();
      }else{
        toast.error(response.data.message);
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'There are some errors in the fields. Please check again.';
      toast.error(errorMessage);
    }
  }

    return (
        <>
        <div className="body d-flex py-3">
          <div className="container-xxl">
            <div className="row align-items-center">
              <div className="border-0 mb-4">
                <div className="card-header py-3 no-bg bg-transparent d-flex align-items-center px-0 justify-content-between border-bottom flex-wrap">
                  <h3 className="fw-bold mb-0">Withdrawals</h3>
                  <Link to="/admin/users/create" className="btn btn-primary step-btn" data-bs-toggle="modal" data-bs-target="#addWithdrawal">+ Add New</Link>
                </div>
              </div>
            </div>
            <div className="row align-item-center">
              <div className="col-md-12">
                <div className="card mb-3">
                  <div className="card-header py-3 bg-transparent border-bottom-0">
                    <h6 className="mb-0 fw-bold">Withdrawal Requests</h6>
                  </div>
                  <div className="card-body">
                    <DataTable
                      columns={columns}
                      data={withdrawData}
                      pagination
                      highlightOnHover
                      striped
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="addWithdrawal" tabIndex="-1" aria-hidden="true" ref={modalRef}>
          <div className="modal-dialog modal-dialog-centered modal-md modal-dialog-scrollable">
              <div className="modal-content">
                  <div className="modal-header">
                      <h5 className="modal-title  fw-bold" id="expeditLabel1111"> New Withdrawal Request</h5>
                      <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                      <div className="deadline-form">
                          <form>
                              <div className="row g-3 mb-3">
                                  <div className="col-sm-6">
                                      <label htmlFor="item100" className="form-label">Full Name</label>
                                      <input 
                                          type="text" 
                                          className="form-control" 
                                          id="item100" 
                                          value={userData.fullName}
                                          disabled
                                      />
                                  </div>
                                  <div className="col-sm-6">
                                      <label htmlFor="mailid" className="form-label">Mail</label>
                                      <input 
                                          type="text" 
                                          className="form-control" 
                                          id="mailid" 
                                          value={userData.email}
                                          disabled
                                      />
                                  </div>
                                  <div className="col-sm-6">
                                      <label htmlFor="account_no" className="form-label">Account No.</label>
                                      <input 
                                          type="text" 
                                          className="form-control" 
                                          id="account_no" 
                                          onChange={(e) => setAccNo(e.target.value)}
                                      />
                                  </div>
                                  <div className="col-sm-6">
                                      <label htmlFor="Amount" className="form-label">Withdrawal Amount</label>
                                      <input 
                                          type="text" 
                                          className="form-control" 
                                          id="amount" 
                                          onChange={(e) => setAmount(e.target.value)}
                                      />
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
                  <div className="modal-footer">
                      <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                      <button onClick={handleSubmit} type="button" className="btn btn-primary">Save</button>
                  </div>
              </div>
          </div>
        </div>
      </>
    );
};

export default Withdrawal;
